import React from "react";
import "./SessionItem.css";
import ASSETS from "../../../../assets/Assets";
import { BsChevronRight } from "react-icons/bs";
import { add, format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

const WELL_BEING = {
  1: ASSETS.sunnyGIF,
  0.5: ASSETS.partlyCloudyGIF,
  0: ASSETS.cloudyGIF,
};

const getWellBeingStatusImage = (well_being_status) => {
  if (well_being_status <= 0.3) {
    return 0;
  } else if (well_being_status <= 0.7 && well_being_status > 0.3) {
    return 0.5;
  } else {
    return 1;
  }
};

function RealtimeSession({ session, setVideoId, setIndex }) {
  console.log(!session.start_datetime);
  const formattedStartTime = session.start_datetime
    ? format(
        parseISO(mysqlDatetimeToJavascriptDatetime(session.start_datetime)),
        "HH:mm"
      )
    : "--";
  const formattedEndTime = session.end_datetime
    ? format(
        parseISO(mysqlDatetimeToJavascriptDatetime(session.end_datetime)),
        "HH:mm"
      )
    : "--";
  return (
    <div
      className="session-item-wrapper"
      onClick={() => {
        setVideoId(session["real_time_session_id"], "realtime");
        setIndex(1);
      }}
    >
      <div className="session-item-date-time">
        <div className="session-item-date">
          {session.start_datetime
            ? format(
                parseISO(
                  mysqlDatetimeToJavascriptDatetime(session.start_datetime)
                ),
                "LLL d, yyyy"
              )
            : "--/--/--"}
        </div>
        <div className="session-item-time">
          {formattedStartTime} - {formattedEndTime}
        </div>
      </div>
      {/* <div className="session-item-under-weather-wrapper">
        <div className="session-item-under-weather-heading">
          Under The Weather Status
        </div>
        {session.well_being_status && (
          <img
            src={WELL_BEING[getWellBeingStatusImage(session.well_being_status)]}
            alt="wellbeing-status"
          />
        )}
        {!session.well_being_status && (
          <div className="session-item-under-weather-icon">NA</div>
        )}
      </div> */}
      <div className="session-item-summary-wrapper">
        <div className="session-item-summary-heading">Session Summary</div>
        <div className="session-item-summary-body">
          {`${session?.summary?.slice(0, 100)}...`}
        </div>
      </div>
      <div className="session-item-edit-icon-wrapper">
        <div className="session-item-edit-icon">
          <img src={ASSETS.pageEditIcon} alt="page-edit" />
        </div>
        <div className="session-item-left-arrow">
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
}

export default RealtimeSession;
