import ClientHeader from "../clientHeader/ClientHeader";
import ClientsSearch from "./clientsSearch";

const ClientHome = function () {
  return (
    <>
      <ClientHeader />
      <ClientsSearch />
    </>
  );
};

export default ClientHome;
