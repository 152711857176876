import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./RealtimeSessionReport.css";

import ASSETS from "../../../../../../assets/Assets";
import { ThreeDots } from "react-loader-spinner";
import { TextField } from "@mui/material";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  createNewNotes,
  fetchNotesByTherapyId,
  updateNotes,
  update_report,
} from "../../../../../../api/notes";
import { getRealtimeSessionInfoById } from "../../../../../../api/realtime";
import buttonLoader from "../../Assets/buttonLoader.gif";

import {
  getNotesFileUploadUrl,
  readUserFilesURL,
  uploadFiles,
} from "../../../../../../api/s3";

const patientObjDefaultProp = {
  name: "",
  patient_id: null,
  email: null,
  therapy_id: null,
};

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "20px 20px",
  width: "100%",
};

const ModalHeader = ({ header }) => {
  return (
    <div
      className="modal-header"
      style={{
        paddingBottom: "0px",
        paddingLeft: "0px",
      }}
    >
      <div className="modal-title">
        <div className="modal-heading">{header}</div>
      </div>
    </div>
  );
};

const textStyle = {
  color: "#282860",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "150%",
};

const chipStyle = {
  padding: "5px 16px",
  borderRadius: "6px",
  background: "#C9CEFA",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#282860",
  fontSize: "12px",
  userSelect: "none",
  cursor: "pointer",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const deleteLastOccurrence = (originalString, substringToDelete) => {
  const lastIndex = originalString.lastIndexOf(substringToDelete);

  if (lastIndex !== -1) {
    const resultString =
      originalString.substring(0, lastIndex) +
      originalString.substring(lastIndex + substringToDelete.length);
    return resultString;
  }

  return originalString;
};

const reportDropOptions = [
  { value: "soap", label: "SOAP Notes" },
  { value: "dap", label: "DAP Notes" },
  { value: "birp", label: "BIRP Notes" },
  { value: "girp", label: "GIRP Notes" },
];

function RealtimeSessionReport({
  soapData,
  soapSuggestions,
  dapData,
  dapSuggestions,
  birpData,
  birpSuggestions,
  girpData,
  girpSuggestions,
  onClose,
  note_id = null,
  addedSuggestionsProp = {},
  selectedLiveSessionId = null,
  transcriptProp = []
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [htmlNoteRecent, setHtmlNoteRecent] = useState("");
  const [notesExpand, setNotesExpand] = useState(false);
  const [transcriptsExpand, setTranscriptsExpand] = useState(false);
  const [reportFormat, setReportFormat] = useState({
    value: "soap",
    label: "SOAP Notes",
  });

  const [soapReport, setSoapReport] = useState(soapData);

  const [suggestions, setSuggestions] = useState(soapSuggestions);
  const [addedSuggestions, setAddedSuggestions] =
    useState(addedSuggestionsProp);
  const [noteIdWithLiveSession, setNoteIdWithLiveSession] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Edit to Save");
  const [isReportEdited, setIsReportEdited] = useState(false);

  let transcript_slice = useSelector((store) => store.liveSession.transcriptArr);
  const liveSessionId = useSelector((store) => store.liveSession.liveSessionId);
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  let note_id_slice = useSelector((store) => store.liveSession.note_id);
  
  note_id_slice = note_id !== null ? note_id : note_id_slice;
  console.log("transcriptProp", transcriptProp)
  // transcript_slice = transcriptProp.length !== 0 ? JSON.parse(transcriptProp) : transcript_slice; 

    if (transcriptProp && transcriptProp.length === 0) {
      transcript_slice = transcript_slice;
    } else {
      transcript_slice =
        typeof transcriptProp === "string"
          ? JSON.parse(transcriptProp)
          : transcriptProp;
    }

  useEffect(() => {
    console.log("this is the live session ID: ", selectedLiveSessionId);
    (liveSessionId || selectedLiveSessionId) &&
      getRealtimeSessionInfoById(
        therapist_id,
        liveSessionId || selectedLiveSessionId
      )
        .then((res) => {
          console.log("----------------This is the response: ", res);
          setNoteIdWithLiveSession(res.data.note_id);
        })
        .catch((err) => {
          console.log("this is the error: ", err);
        });
  }, [liveSessionId, selectedLiveSessionId]);

  useEffect(() => {
    console.log("Drop down changed: ", reportFormat);
    setIsReportEdited(false);
    setButtonText("Edit to Save");
    if (reportFormat.value === "soap") {
      setSoapReport(soapData);
      setSuggestions(soapSuggestions);
      setAddedSuggestions(addedSuggestionsProp.soap || {});
    } else if (reportFormat.value === "dap") {
      setSoapReport(dapData);
      setSuggestions(dapSuggestions);
      setAddedSuggestions(addedSuggestionsProp.dap || {});
    } else if (reportFormat.value === "birp") {
      setSoapReport(birpData);
      setSuggestions(birpSuggestions);
      setAddedSuggestions(addedSuggestionsProp.birp || {});
    } else if (reportFormat.value === "girp") {
      setSoapReport(girpData);
      setSuggestions(girpSuggestions);
      setAddedSuggestions(addedSuggestionsProp.girp || {});
    }
  }, [reportFormat]);

  useEffect(() => {
    console.log("This is the added suggestions: ", addedSuggestions);
  }, [addedSuggestions]);

  const therapy_id = searchParams.get("therapy_id");

  useEffect(() => {
    if (therapy_id) fetchAllNotes(therapy_id);
  }, [therapy_id]);

  const onSaveClick = () => {
    // save the progress notes here as well once the user clicks to save it
    setIsLoading(true);
    setButtonText("Saving...");
    // setIsReportEdited(false);
    if (reportFormat.value === "soap") {
      console.log("only soap is edited");
      update_report(therapy_id, note_id_slice, "soap", {
        ...soapReport,
        subjective_suggestions: JSON.stringify({
          suggestions: suggestions.subjective,
          addedSuggestions: addedSuggestions.subjective,
        }),
        objective_suggestions: JSON.stringify({
          suggestions: suggestions.objective,
          addedSuggestions: addedSuggestions.objective,
        }),
        assessment_suggestions: JSON.stringify({
          suggestions: suggestions.assessment,
          addedSuggestions: addedSuggestions.assessment,
        }),
        plan_suggestions: JSON.stringify({
          suggestions: suggestions.plan,
          addedSuggestions: addedSuggestions.plan,
        }),
        updated_at: new Date(),
      })
        .then((res) => {
          console.log("updated soap: ", res);
          setButtonText("Success");
          setIsLoading(false);
          setIsReportEdited(false);
        })
        .catch((err) => {
          console.log("Some error occured: ", err);
          setIsLoading(false);
          setButtonText("Save to Notes");
          setIsReportEdited(false);
        });
    } else if (reportFormat.value === "dap") {
      console.log("only dap is edited");
      update_report(therapy_id, note_id_slice, "dap", {
        ...soapReport,
        data_suggestions: JSON.stringify({
          suggestions: suggestions.data,
          addedSuggestions: addedSuggestions.data,
        }),
        assessment_suggestions: JSON.stringify({
          suggestions: suggestions.assessment,
          addedSuggestions: addedSuggestions.assessment,
        }),
        plan_suggestions: JSON.stringify({
          suggestions: suggestions.plan,
          addedSuggestions: addedSuggestions.plan,
        }),
        updated_at: new Date(),
      })
        .then((res) => {
          console.log("updated dap: ", res);
          setButtonText("Success");
          setIsLoading(false);
          setIsReportEdited(false);
        })
        .catch((err) => {
          console.log("Some error occured: ", err);
          setButtonText("Save to Notes");
          setIsLoading(false);
          setIsReportEdited(false);
        });
    } else if (reportFormat.value === "birp") {
      console.log("only birp is edited");
      update_report(therapy_id, note_id_slice, "birp", {
        ...soapReport,
        behavior_suggestions: JSON.stringify({
          suggestions: suggestions.behavior,
          addedSuggestions: addedSuggestions.behavior,
        }),
        intensity_suggestions: JSON.stringify({
          suggestions: suggestions.intensity,
          addedSuggestions: addedSuggestions.intensity,
        }),
        response_suggestions: JSON.stringify({
          suggestions: suggestions.response,
          addedSuggestions: addedSuggestions.response,
        }),
        plan_suggestions: JSON.stringify({
          suggestions: suggestions.plan,
          addedSuggestions: addedSuggestions.plan,
        }),
        updated_at: new Date(),
      })
        .then((res) => {
          console.log("updated birp: ", res);
          setButtonText("Success");
          setIsLoading(false);
          setIsReportEdited(false);
        })
        .catch((err) => {
          console.log("Some error occured: ", err);
          setButtonText("Save to Notes");
          setIsLoading(false);
          setIsReportEdited(false);
        });
    } else if (reportFormat.value === "girp") {
      console.log("only girp is edited: ", soapReport);
      update_report(therapy_id, note_id_slice, "girp", {
        ...soapReport,
        goals_suggestions: JSON.stringify({
          suggestions: suggestions.goals,
          addedSuggestions: addedSuggestions.goals,
        }),
        interventions_suggestions: JSON.stringify({
          suggestions: suggestions.interventions,
          addedSuggestions: addedSuggestions.interventions,
        }),
        response_suggestions: JSON.stringify({
          suggestions: suggestions.response,
          addedSuggestions: addedSuggestions.response,
        }),
        plan_suggestions: JSON.stringify({
          suggestions: suggestions.plan,
          addedSuggestions: addedSuggestions.plan,
        }),
        updated_at: new Date(),
      })
        .then((res) => {
          console.log("updated grip: ", res);
          setButtonText("Success");
          setIsLoading(false);
          setIsReportEdited(false);
        })
        .catch((err) => {
          console.log("Some error occured: ", err);
          setButtonText("Save to Notes");
          setIsLoading(false);
          setIsReportEdited(false);
        });
    }
    console.log("Save Button is clicked", reportFormat);
    console.log(
      "This is the SOAP report that is needed to be saved: ",
      soapReport
    );
    console.log(
      "This is the Suggestions Panel that is needed to be saved: ",
      suggestions
    );
  };

  const fetchAllNotes = async (therapy_id) => {
    const fetchNoteFiles = async (note) => {
      const noteURL = await readUserFilesURL(note.note_bucket_key);
      console.log("------------ This is the note", note);
      const res = await axios.get(noteURL, { responseType: "blob" });
      let htmlContent = "";
      const reader = new FileReader();
      reader.onload = function () {
        htmlContent = reader.result;
        console.log("This is the note: ", htmlContent);
        setHtmlNoteRecent(htmlContent);
      };
      reader.readAsText(res.data);
    };

    fetchNotesByTherapyId(therapy_id).then((data) => {
      let allNotes = data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );

      console.log("This is the notes: ", allNotes);

      (selectedLiveSessionId || liveSessionId) &&
        getRealtimeSessionInfoById(
          therapist_id,
          liveSessionId ? liveSessionId : selectedLiveSessionId
        )
          .then((res) => {
            console.log(
              "----------------This is the response: ",
              res.data.note_id
            );

            if (allNotes.length !== 0) {
              console.log(
                `------------ checking if , ${res.data.note_id},  is in the notes ,`,
                allNotes
              );
              const index = allNotes.findIndex(
                (note) => note.note_id === res.data.note_id
              ); // send this noteId to the above function to show it during report generation
              if (index !== -1) {
                console.log(
                  `Index of note with note_id ${res.data.note_id}: ${index}`
                );

                console.log(
                  "Fetching the notes : ------------------",
                  allNotes[index]
                );
                allNotes.length !== 0 &&
                  index !== -1 &&
                  fetchNoteFiles(allNotes[index]);
              } else {
                console.log(
                  `Note with note_id ${res.data.note_id} not found in the array.`
                );
              }
            }
          })
          .catch((err) => {
            console.log("this is the error: ", err);
          });
      // setNotes(allNotes);
      // setNotesFetching(false);
    });
  };

  const SoapReport = () => {
    const reportHeaderText = {
      color: "#282860",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0.32px",
    };

    return (
      <div className="scroll-thin-vertical">
        {Object.keys(soapReport).map((item, index) => {
          return (
            <div key={index}>
              <span style={{ ...reportHeaderText }}>
                {capitalizeFirstLetter(item)}
              </span>
              <textarea
                className="text-input-field description-textarea scroll-thin-vertical"
                placeholder="type here.."
                required
                // defaultValue={soapReport[item]}
                value={soapReport[item]}
                style={{ height: "8rem" }}
                onChange={(e) => {
                  setButtonText("Save to Notes");
                  setIsReportEdited(true);
                  setSoapReport((prevState) => ({
                    ...prevState,
                    [item]: e.target.value,
                  }));
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const SuggestionPanel = () => {
    return (
      <div style={{ ...containerStyleBasic }}>
        <div style={{ ...textStyle }}>Suggestion Panel</div>
        <div>
          {Object.entries(suggestions).map((key, index) => {
            return (
              <>
                <p
                  key={index}
                  style={{ ...textStyle, opacity: 0.8, fontSize: "14px" }}
                >
                  {capitalizeFirstLetter(key[0])}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {suggestions[key[0]].map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          ...chipStyle,
                          background: addedSuggestions[key[0]]?.includes(
                            item.topic_name
                          )
                            ? "linear-gradient(0deg, rgba(236, 116, 64, 0.35) 0%, rgba(236, 116, 64, 0.35) 100%), #FFF"
                            : "#C9CEFA",
                        }}
                        onClick={() => {
                          const clickedTopic = item.topic_name;
                          const isAlreadyAdded =
                            addedSuggestions[key[0]]?.includes(clickedTopic);
                          setIsReportEdited(true);
                          setButtonText("Save to Notes");

                          if (isAlreadyAdded) {
                            const newAddedSuggestions = addedSuggestions[
                              key[0]
                            ].filter(
                              (suggestion) => suggestion !== clickedTopic
                            );
                            const newSoapReportString = deleteLastOccurrence(
                              soapReport[key[0]],
                              item.text
                            );

                            setSoapReport((prevState) => ({
                              ...prevState,
                              [key[0]]: newSoapReportString,
                            }));

                            setAddedSuggestions((prevState) => ({
                              ...prevState,
                              [key[0]]: newAddedSuggestions,
                            }));
                          } else {
                            setSoapReport((prevState) => ({
                              ...prevState,
                              [key[0]]: prevState[key[0]] + " " + item.text,
                            }));

                            setAddedSuggestions((prevState) => ({
                              ...prevState,
                              [key[0]]: [
                                ...(prevState[key[0]] || []),
                                clickedTopic,
                              ],
                            }));
                          }

                          console.log(
                            "clicked heading: ",
                            clickedTopic,
                            "clicked text: ",
                            item.text,
                            " ",
                            key[0]
                          );
                        }}
                      >
                        {addedSuggestions[key[0]]?.includes(item.topic_name) ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "14px",
                              width: "14px",
                            }}
                          >
                            <img src={ASSETS.removeIconBlack} alt="remove" />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "14px",
                              width: "14px",
                            }}
                          >
                            <img src={ASSETS.addSignBlue} alt="add" />
                          </div>
                        )}
                        {item.topic_name}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const YourNotes = ({ style }) => {
    return (
      <>
        {/* <div style={{ ...containerStyleBasic, ...style, width: "100%" }}> */}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ ...textStyle, marginBottom: "10px" }}>
              Your Notes
            </span>
            <img
              src={ASSETS.downArrow}
              alt="your notes"
              style={{
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => {
                setNotesExpand(!notesExpand);
              }}
            />
          </div>
          {/* <div
            style={{
              ...textStyle,
              fontWeight: 500,
              opacity: 0.7,
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            The notes you jotted down during the session.
          </div> */}
          <div
            className={`scroll-thin-vertical collapsible ${
              notesExpand ? "expanded" : "collapsed"
            }`}
            style={{
              ...textStyle,
              fontWeight: 500,
            }}
            dangerouslySetInnerHTML={{ __html: htmlNoteRecent }}
          ></div>
        </div>
      </>
    );
  };

  const TranscriptView = () => {
    console.log("This should be the transcript: ", transcript_slice)
    return (
      <>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                ...textStyle,
                marginTop: "10px",
                marginBottom: transcriptsExpand ? "20px" : "0px",
              }}
            >
              Session Transcription
            </span>
            <img
              src={ASSETS.downArrow}
              alt="your notes"
              style={{
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => {
                setTranscriptsExpand(!transcriptsExpand);
              }}
            />
          </div>
          <div
            className={`scroll-thin-vertical collapsible ${
              transcriptsExpand ? "expanded" : "collapsed"
            }`}
            style={{
              ...textStyle,
              fontWeight: 500,
            }}
          >
            {transcript_slice.map((item, index) => {
              const speakerName = item.role ? item.role : item.speaker;
              const isClient = speakerName?.toLowerCase() === "client";
              const isTherapist = speakerName?.toLowerCase() === "therapist";
              
              return (
                <>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: isClient ? "#EC7440" : "#282860",
                    }}
                  >
                    {isTherapist
                      ? "You"
                      : capitalizeFirstLetter(speakerName)}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginBottom: "20px",
                    }}
                  >
                    {item.content}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="modal" onClick={onClose}>
        <div
          className="modal-content scroll-remove scroll-thin-vertical"
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "90dvw",
            height: "90dvh",
            padding: "0px 40px 0px 40px",
            // overflowY: "hidden",
          }}
        >
          {false ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <ThreeDots
                height="100"
                width="100"
                color="#5F6CE1"
                radius="50"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
              />
            </div>
          ) : (
            <>
              <span className="close-modal-btn">
                <img
                  src={ASSETS.modalCloseIcon}
                  alt="close modal"
                  onClick={onClose}
                />
              </span>

              <ModalHeader header={"Ai Generated Report"} />

              <div
                className="ai-analysis-report-container"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "30px",
                  }}
                >
                  <div style={{ flex: 7 }}>
                    <p className="modal-heading-description">
                      Here's an AI-generated report featuring your session
                      notes. Customise the format, leverage the suggestions
                      panel on the left for context updates, and edit the
                      AI-generated content as needed.
                    </p>

                    <div className="report-format-selector-container">
                      <p>Report Format:</p>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "200px",
                          }),
                        }}
                        maxMenuHeight={"200px"}
                        options={reportDropOptions}
                        value={reportDropOptions[reportFormat]}
                        placeholder="SOAP Notes"
                        onChange={(item) => setReportFormat(item)}
                      />
                    </div>

                    {SoapReport()}
                  </div>
                  <div className="right-panel">
                    <div style={{ ...containerStyleBasic, width: "100%" }}>
                      <YourNotes />
                      <hr style={{ opacity: 0.5 }} />
                      <TranscriptView />
                    </div>
                    <SuggestionPanel />
                    <div
                      className={
                        !isReportEdited ? `save-button-inactive` : `save-button`
                      }
                      style={{
                        pointerEvents:
                          isLoading || !isReportEdited ? "none" : "auto",
                      }}
                      onClick={() => onSaveClick()}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {/* <span>Save to Notes</span> */}
                        <span>{buttonText}</span>
                        {isLoading && (
                          <img
                            src={buttonLoader}
                            alt="loader"
                            style={{
                              height: "22px",
                              width: "22px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default RealtimeSessionReport;
