import { createBrowserHistory } from "history";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

// TODO: import all reducers down below here.
import blogsSliceReducer from "./blogs/blogsSlice";
import clientSliceReducer from "./client/clientSlice";
import filesSliceReducer from "./files/filesSlice";
import sessionsSliceReducer from "./sessions/sessionsSlice";
import therapistSliceReducer from "./therapist/therapistSlice";
import activitySliceReducer from "./activities/activitySlice";
import therapistDashboardSliceReducer from "./therapistDashboard/therapistDashboardSlice";
import liveSessionSliceReducer from "./liveSession/liveSessionSlice";
import clientDashboardSliceReducer from "./clientDashboard/clientDashboardSlice";
import overviewDashboardSliceReducer from "./overviewDashboard/overviewDashboardSlice";

export const history = createBrowserHistory();

const combinedReducers = combineReducers({
  blogs: blogsSliceReducer,
  client: clientSliceReducer,
  files: filesSliceReducer,
  sessions: sessionsSliceReducer,
  therapist: therapistSliceReducer,
  activities: activitySliceReducer,
  therapistDashboard: therapistDashboardSliceReducer,
  liveSession: liveSessionSliceReducer,
  clientDashboard: clientDashboardSliceReducer,
  overviewDashboard: overviewDashboardSliceReducer,
});

// combineReducers will be handled internally by configureStore
const rootReducer = (state, action) => {
  if (action.type === "therapist/logoutResetStore") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

export default store;
