import "./CreateClientByNameModal.css";
import ASSETS from "../../assets/Assets";

import { useState } from "react";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import { createClientUsingNameAPI } from "../../api/therapist";
import { toast } from "react-hot-toast";

const CreateClientByName = ({ show, onClose }) => {
  const [createClient, setCreateClient] = useState(false);
  const [createClientIsSuccess, setCreateClientIsSuccess] = useState(false);
  const therapistId = useSelector((store) => store.therapist.therapist_id);

  const formSchema = yup
    .object({
      name: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  if (!show) return null;

  const createClientCallback = async (data) => {
    try {
      const response = await createClientUsingNameAPI(data.name);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        setCreateClientIsSuccess(true);
        toast.success("Client Added successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("Failed to add client");
    }

    setCreateClient(true);
  };

  const resetStateAndCloseModal = (e) => {
    // Reset the success feedback elements, so the user can create more invites
    // when they reopen the modal.
    setCreateClient(false);
    onClose(e);
    setCreateClientIsSuccess(false);
  };

  return (
    <div className="modal" onClick={resetStateAndCloseModal}>
      <div
        className="modal-content scroll-remove"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={resetStateAndCloseModal}
          />
        </span>
        <div className="modal-header">
          <div className="modal-title">
            <div className="modal-heading">Add Client</div>
            <div className="modal-header-text">
              Simply add a client by entering their full name. After that, you
              can start a real-time session with them. It's that easy!
            </div>
          </div>
        </div>
        <div className="modal-content-divider" />
        <div className="modal-body">
          <div className="modal-subheading sub-heading">Name</div>
          <form onSubmit={handleSubmit(createClientCallback)}>
            {!createClient && (
              <div className="email-invite-row row">
                <input
                  type="text"
                  className="text-input-field"
                  defaultValue=""
                  placeholder="Your client's name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                />

                <input
                  type="submit"
                  className="add-client-btn"
                  value="Add Client"
                />
              </div>
            )}

            {createClient && (
              <div
                className={
                  createClientIsSuccess
                    ? "invite-sent-confirmation"
                    : "invite-sent-failed"
                }
              >
                {createClientIsSuccess ? `Added!` : `Failed!`}
              </div>
            )}
            {errors.name && (
              <div className="info-text-danger form-error-message">
                {errors.name?.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateClientByName;
