import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/select";
import { Button } from "../../components/button";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { X, Brain, Heart, MessageCircle, Zap, Loader2 } from 'lucide-react';
import ClientView from './MiddleView';
import SessionView from './LowView';
import { getSupervisorAnalysisAggregatedReport } from "../../api/supervisor";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

const Analysis = () => {
    // State for dropdown selections
    const [therapists, setTherapists] = useState([]);
    const [selectedTherapistId, setSelectedTherapistId] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const therapist_id = useSelector((store) => store.therapist.therapist_id);
    const [loading, setLoading] = useState(true);

    const handleSessionSelect = (sessionDate) => {
        setSelectedSession(sessionDate);
    };

    const [aggregatedData, setAggregatedData] = useState(null);


    // Sample data - in real app, this would come from your backend
    // const therapists = ['Dr. Sarah Wilson', 'Dr. Michael Chen'];
    const patients = {
        'Dr. Sarah Wilson': ['John Smith', 'Emma Davis'],
        'Dr. Michael Chen': ['Alex Johnson', 'Maria Garcia']
    };

    const interventionData = [
        { session: 1, score: 65 },
        { session: 2, score: 72 },
        { session: 3, score: 68 },
        { session: 4, score: 75 }
    ];


    const handleTherapistChange = (value) => {
        setSelectedTherapistId(value);
        setSelectedClientId(null);
        setSelectedSession(null);
    };

    const handleClientChange = (value) => {
        setSelectedClientId(value);
        setSelectedSession(null);
    };
    const handleSessionChange = (value) => {
        setSelectedSession(value);
    };
    const handleClearTherapist = () => {
        setSelectedTherapistId(null);
        setSelectedClientId(null);
        setSelectedSession(null);
    };
    const handleClearClient = () => {
        setSelectedClientId(null);
        setSelectedSession(null);
    };
    const handleClearSession = () => {
        setSelectedSession(null);
    };

    useEffect(() => {
        const fetchAggregatedReport = async () => {
            setLoading(true);
            try {
                // API呼び出し
                const { data } = await getSupervisorAnalysisAggregatedReport(therapist_id);
                setAggregatedData(data);

                // therapistsドロップダウン用に抽出
                const tList = data.data.map(t => ({
                    id: t.therapist_id,
                    name: t.therapist_name
                }));
                setTherapists(tList);

                localStorage.setItem(`supervisorAggregatedData_${therapist_id}`, JSON.stringify(data));
                const today = dayjs().format("YYYY-MM-DD");
                localStorage.setItem(`supervisorAggregatedDataDate_${therapist_id}`, today);

                console.log("Aggregated data fetched from API");
            } catch (err) {
                console.error("Failed to fetch aggregated report", err);
            } finally {
                setLoading(false);
            }
        };

        // 1) Local Storage に既に保存されていれば読み込む
        const cachedData = localStorage.getItem(`supervisorAggregatedData_${therapist_id}`);
        const cachedDate = localStorage.getItem(`supervisorAggregatedDataDate_${therapist_id}`);
        const today = dayjs().format("YYYY-MM-DD");

        if (cachedData && cachedDate === today) {
            // 同じ日付ならそのままキャッシュを使う
            console.log("Using cached aggregator data from LocalStorage");
            setAggregatedData(JSON.parse(cachedData));
            // therapistリストの抽出
            const parsed = JSON.parse(cachedData);
            const tList = parsed.data.map(t => ({
                id: t.therapist_id,
                name: t.therapist_name
            }));
            setTherapists(tList);
            setLoading(false);
        } else {
            // キャッシュがない or 日付が変わっていたら再取得
            fetchAggregatedReport();
        }
    }, [therapist_id]);


    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Loader2 className="h-8 w-8 animate-spin  text-blue-500" />
            </div>
        );
    }

    const currentTherapistData = aggregatedData?.data?.find(
        (t) => t.therapist_id === selectedTherapistId
    );

    const clientList = currentTherapistData?.clients || [];

    const sessionList = selectedClientId
        ? clientList.find((c) => c.therapy_id === selectedClientId)?.sessions || []
        : [];

    const sortedSessionList = [...sessionList].sort((a, b) => {
        const aDateStr = a.start_datetime || a.end_datetime;
        const bDateStr = b.start_datetime || b.end_datetime;
        const aTime = aDateStr ? new Date(aDateStr).getTime() : 0;
        const bTime = bDateStr ? new Date(bDateStr).getTime() : 0;
        return bTime - aTime;
    });

    const selectedClientData = clientList.find(c => c.therapy_id === selectedClientId);

    // Client card component
    // const ClientCard = ({ name }) => {
    //     const scores = {
    //         engagement: 78,
    //         empathy: 85,
    //         communication: 82,
    //         intervention: 75
    //     };

    //     return (
    //         <Card className="hover:shadow-lg transition-shadow cursor-pointer">
    //             <CardContent className="pt-6">
    //                 <div className="flex justify-between">
    //                     <div className="space-y-4 flex-1">
    //                         <h3 className="font-semibold text-lg text-gray-900">{name}</h3>

    //                         <div className="grid grid-cols-2 gap-4">
    //                             <div className="flex items-center gap-2">
    //                                 <Heart className="h-4 w-4 text-blue-500" />
    //                                 <div>
    //                                     <p className="text-sm text-gray-600">Empathy</p>
    //                                     <p className="font-medium text-gray-900">{scores.empathy}%</p>
    //                                 </div>
    //                             </div>

    //                             <div className="flex items-center gap-2">
    //                                 <MessageCircle className="h-4 w-4 text-blue-500" />
    //                                 <div>
    //                                     <p className="text-sm text-gray-600">Engagement</p>
    //                                     <p className="font-medium text-gray-900">{scores.engagement}%</p>
    //                                 </div>
    //                             </div>

    //                             <div className="flex items-center gap-2">
    //                                 <Brain className="h-4 w-4 text-blue-500" />
    //                                 <div>
    //                                     <p className="text-sm text-gray-600">Communication</p>
    //                                     <p className="font-medium text-gray-900">{scores.communication}%</p>
    //                                 </div>
    //                             </div>

    //                             <div className="flex items-center gap-2">
    //                                 <Zap className="h-4 w-4 text-blue-500" />
    //                                 <div>
    //                                     <p className="text-sm text-gray-600">Intervention</p>
    //                                     <p className="font-medium text-gray-900">{scores.intervention}%</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div className="w-48 h-32">
    //                         <ResponsiveContainer width="100%" height="100%">
    //                             <LineChart data={interventionData}>
    //                                 <XAxis dataKey="session" />
    //                                 <YAxis domain={[0, 100]} hide />
    //                                 <Tooltip />
    //                                 <Line
    //                                     type="monotone"
    //                                     dataKey="score"
    //                                     stroke="#3B82F6"
    //                                     strokeWidth={2}
    //                                     dot={{ r: 4 }}
    //                                 />
    //                             </LineChart>
    //                         </ResponsiveContainer>
    //                     </div>
    //                 </div>
    //             </CardContent>
    //         </Card>
    //     );
    // };

    const ClientCard = ({
        name,
        empathy,
        engagement,
        communication,
        intervention,
        sessionCount,
        sessionData,
        onClick
    }) => {
        // sessionData = [{ intervention: number, ...}, ...]
        // ここでLineChart用のデータを作成
        // 例: session: index+1, score: session.intervention
        const interventionData = sessionData.map((s, idx) => ({
            session: idx + 1,
            score: s.intervention || 0
        }));

        return (
            <Card onClick={onClick} className="hover:shadow-lg transition-shadow cursor-pointer">
                <CardContent className="pt-6">
                    <div className="flex justify-between">
                        <div className="space-y-4 flex-1">
                            <h3 className="font-semibold text-lg text-gray-900 mt-0">{name}</h3>

                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center gap-2">
                                    <Heart className="h-4 w-4 text-blue-500" />
                                    <div>
                                        <p className="text-sm text-gray-600  my-0">Empathy</p>
                                        <p className="font-medium text-gray-900  my-0">{empathy}%</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    <MessageCircle className="h-4 w-4 text-blue-500" />
                                    <div>
                                        <p className="text-sm text-gray-600 my-0">Engagement</p>
                                        <p className="font-medium text-gray-900 my-0">{engagement}%</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    <Brain className="h-4 w-4 text-blue-500" />
                                    <div>
                                        <p className="text-sm text-gray-600  my-0">Communication</p>
                                        <p className="font-medium text-gray-900  my-0">{communication}%</p>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    <Zap className="h-4 w-4 text-blue-500" />
                                    <div>
                                        <p className="text-sm text-gray-600  my-0">Intervention</p>
                                        <p className="font-medium text-gray-900  my-0">{intervention}%</p>
                                    </div>
                                </div>
                            </div>

                            {/* <p className="text-sm text-gray-500 mt-4">
                                Sessions: {sessionCount}
                            </p> */}
                        </div>

                        <div className="w-48 h-32">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={interventionData}>
                                    <XAxis dataKey="session" />
                                    <YAxis domain={[0, 100]} hide />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="score"
                                        stroke="#3B82F6"
                                        strokeWidth={2}
                                        dot={{ r: 4 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    };

    return (
        <div className="p-6 space-y-6 bg-gray-50 min-h-screen w-full ml-32 mr-24 mt-16">
            {/* Header */}
            <div>
                <h1 className="text-2xl font-bold text-gray-900">Therapy Session Analysis</h1>
                <p className="text-gray-600">Select a therapist to view their clients</p>
            </div>

            {/* Dropdowns */}
            <div className="flex gap-4">
                <div className="w-64 flex gap-2">
                    <Select
                        value={selectedTherapistId || undefined}
                        onValueChange={handleTherapistChange}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select Therapist" />
                        </SelectTrigger>
                        <SelectContent>
                            {therapists.map((th) => (
                                <SelectItem key={th.id} value={th.id}>
                                    {th.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {selectedTherapistId && (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={handleClearTherapist}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    )}
                </div>

                <div className="w-64 flex gap-2">
                    <Select
                        value={selectedClientId || undefined}
                        onValueChange={handleClientChange}
                        disabled={!selectedTherapistId}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select Patient" />
                        </SelectTrigger>
                        <SelectContent>
                            {clientList.map((clientObj) => (
                                <SelectItem key={clientObj.therapy_id} value={clientObj.therapy_id}>
                                    {clientObj.therapy_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {selectedClientId && (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={handleClearClient}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    )}
                </div>

                <div className="w-64 flex gap-2">
                    <Select
                        value={selectedSession || undefined}
                        onValueChange={handleSessionChange}
                        disabled={!selectedClientId}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select Session" />
                        </SelectTrigger>
                        <SelectContent>
                            {sortedSessionList.map((s, i) => {
                                const label = s.start_datetime?.substring(0, 10) || s.end_datetime?.substring(0, 10) || `Session ${i + 1}`;
                                return (
                                    <SelectItem key={s.session_id} value={s.session_id}>
                                        {label}
                                    </SelectItem>
                                );
                            })}
                        </SelectContent>
                    </Select>
                    {selectedSession && (
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={handleClearSession}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    )}
                </div>
            </div>

            {/* Therapist -> Clients Overview */}
            {/* {selectedTherapistId && !selectedClientId && (
                <div className="grid grid-cols-2 gap-6">
                    {clientList.map((clientObj) => (
                        <div
                            key={clientObj.therapy_id}
                            onClick={() => setSelectedClientId(clientObj.therapy_id)}
                        >
                            <Card className="hover:shadow-lg transition-shadow cursor-pointer">
                                <CardContent className="pt-6">
                                    <h3 className="font-semibold text-lg text-gray-900">
                                        {clientObj.therapy_name}
                                    </h3>
                                    <p className="text-gray-600 mt-2">Avg Empathy: {clientObj.avg_empathy}%</p>
                                    <p className="text-gray-600">Avg Engagement: {clientObj.avg_engagement}%</p>
                                    <p className="text-gray-600">Avg Communication: {clientObj.avg_communication}%</p>
                                    <p className="text-gray-600">Avg Intervention: {clientObj.avg_intervention}%</p>
                                    <p className="text-sm text-gray-500 mt-4">
                                        Sessions: {clientObj.session_count}
                                    </p>
                                </CardContent>
                            </Card>
                        </div>
                    ))}
                </div>
            )} */}

            {/*
  Therapist -> Clients Overview
  現在の inline で <Card> を出している箇所を
  <ClientCard> を呼び出す形に書き換えます。
*/}

            {selectedTherapistId && !selectedClientId && (
                <div className="grid grid-cols-2 gap-6">
                    {clientList.map((clientObj) => (
                        <ClientCard
                            key={clientObj.therapy_id}
                            name={clientObj.therapy_name}
                            // アグリゲートした平均スコアをpropsに渡す
                            empathy={Math.round(clientObj.avg_empathy)}
                            engagement={Math.round(clientObj.avg_engagement)}
                            communication={Math.round(clientObj.avg_communication)}
                            intervention={Math.round(clientObj.avg_intervention)}
                            sessionCount={clientObj.session_count}
                            // セッション単位のデータ配列 (LineChartで使う)
                            sessionData={clientObj.sessions}
                            // カードクリック時にクライアント選択
                            onClick={() => setSelectedClientId(clientObj.therapy_id)}
                        />
                    ))}
                </div>
            )}

            {/* ClientView */}
            {selectedTherapistId && selectedClientId && !selectedSession && (
                <div className="grid grid-cols-1 gap-6">
                    <ClientView
                        clientName={selectedClientData?.therapy_name}
                        clientData={selectedClientData}
                        onSessionSelect={(sessionDate) => {
                            // sessionDateにはセッションID等を受け取ることも想定
                            setSelectedSession(sessionDate);
                        }} />
                </div>
            )}

            {/* SessionView */}
            {selectedTherapistId && selectedClientId && selectedSession && (
                <div className="grid grid-cols-1 gap-6">
                    <SessionView
                        therapistId={selectedTherapistId}
                        sessionId={selectedSession}
                    // sessionData={selectedSession} 
                    />
                </div>
            )}
        </div>

    );
};

export default Analysis;