import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "../store/rootStore";

import TherapistDashboard from "../screens/dashboard/Dashboard";
import Sidebar from "../components/sidebar/Sidebar";
import Settings from "../screens/settings/Settings";
import TherapistAuthApp from "../components/auth/therapist/TherapistAuthApp";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import { therapists_aws } from "../utils/aws-exports";
import ClientChart from "../screens/dashboard/components/clientChart/clientChart";
import ClientScreen from "../screens/dashboard/Client";
import Unverified from "../screens/unverified/Unverified";
import Team from "../screens/team/Team";
import Analysis from "../screens/analysis/Analysis";
import { Toaster } from "react-hot-toast";

import { getTherapistDashboardByEmailAPI } from "../api/therapist";
import { getTherapistClientsByName } from "../api/therapist";
import { getSupervisorOverview } from "../api/therapist";
import { fetchSessionsAPI } from "../api/sessions";
import { setSessions } from "../store/sessions/sessionsSlice";
import { setTherapistDashboard } from "../store/therapistDashboard/therapistDashboardSlice";
import {
  setClientDashboard,
  setClientDashboardLoading,
} from "../store/clientDashboard/clientDashboardSlice";

import { ThreeDots } from "react-loader-spinner";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ManualUpload from "../screens/manualUpload/ManualUpload";
import Overview from "../screens/overview/Overview";
import {
  setOverviewDashboard,
  setOverviewDashboardLoading,
} from "../store/overviewDashboard/overviewDashboardSlice";


Amplify.configure(therapists_aws);

const theme = createTheme({
  palette: {
    primary: {
      main: "#5F6CE1",
    },
  },
});

const TherapistApp = ({ logOut }) => {
  // const { status } = useSelector((store) => store.therapist);
  // console.log(status);
  //const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { user } = useAuthenticator((context) => [
    context.user,
    context.user.attributes,
  ]);
  const [status, setStatus] = useState("");
  // if user_status is verified, then load this component
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    if (user && user["attributes"]) {
      setStatus(user["attributes"]["custom:user_status"]);
      if (
        user["attributes"]["custom:user_status"] === "VERIFIED" ||
        user["attributes"]["custom:user_status"] === "SKIPPED"
      ) {
        const therapist_id = user.attributes["sub"];
        const email = user.attributes["email"];
        setUserRole(user["attributes"]["custom:user_type"]);

        dispatch(setClientDashboardLoading(true));
        dispatch(setOverviewDashboardLoading(true));

        Promise.all([
          fetchSessionsAPI(therapist_id).then((res) => {
            dispatch(setSessions(res));
          }),
          getTherapistDashboardByEmailAPI(email).then((res) => {
            dispatch(setTherapistDashboard(res.data));
          }),
          getTherapistClientsByName("", therapist_id).then((res) => {
            dispatch(setClientDashboard(JSON.parse(res)));
          }),
          getSupervisorOverview(therapist_id).then((res) => {
            console.log(res);
            if (user["attributes"]["custom:user_type"] === "SUPERVISOR") {
              dispatch(setOverviewDashboard(res));
            }
          }),
        ]).then((res) => {
          //setLoading(false);
          dispatch(setClientDashboardLoading(false));
          dispatch(setOverviewDashboardLoading(false));
        });
      }
    }
  }, [user]);

  return (
    <>
      {status === "UNVERIFIED" ? (
        <Unverified logOut={logOut} />
      ) : status === "VERIFIED" || status === "SKIPPED" ? (
        <div className="app">
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Sidebar userRole={userRole} />
              <div className="main-content-wrapper">
                <Routes>
                  <Route
                    path="/"
                    element={
                      userRole === "SUPERVISOR" ? (
                        <Navigate to="/overview" replace />
                      ) : userRole === "THERAPIST" ? (
                        <Navigate to="/dashboard" replace />
                      ) : (
                        <Navigate to="/dashboard" replace />
                      )
                    }
                  />
                  {userRole === "SUPERVISOR" && (
                    <Route path="/overview" element={<Overview />} />
                  )}
                  <Route path="/dashboard" element={<TherapistDashboard />} />
                  <Route path="/clients/*" element={<ClientScreen />} />
                  {userRole === "SUPERVISOR" && (
                    <Route path="/team" element={<Team />} />
                  )}
                  <Route path="/analysis" element={<Analysis />} />
                  {/* <Route path="/manual-upload/*" element={<ManualUpload />} /> */}
                  <Route
                    path="/settings"
                    element={<Settings logOut={logOut} />}
                  />
                  {/* Fallback Route */}
                  <Route
                    path="*"
                    element={<div>Seems like you are lost</div>}
                  />
                </Routes>
              </div>
              <Toaster position="bottom-right" reverseOrder={false} />
            </LocalizationProvider>
          </ThemeProvider>
        </div>
      ) : (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      )}
    </>
  );
};

const MindlyTherapistApp = () => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <TherapistAuthApp App={TherapistApp} />
      </Provider>
    </Authenticator.Provider>
  );
};
export default MindlyTherapistApp;
