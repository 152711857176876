import React, { useState } from 'react';
import { 
  Users, 
  UserPlus, 
  Search, 
  Sparkles, 
  Star, 
  Clock, 
  ArrowRight,
  BellRing,
  Mail 
} from "lucide-react";
import { useNavigate } from 'react-router-dom';

const ClientList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeNav, setActiveNav] = useState('new');
  const navigate = useNavigate();
  
  const clients = [
    { 
      id: 'NO', 
      name: 'Nose', 
      email: 'nose@example.com',
      lastSession: '17th Dec 2024', 
      type: 'all',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-600',
      isNew: true
    },
    { 
      id: 'SH', 
      name: 'Shubham', 
      email: 'shubham@example.com',
      lastSession: '6th Dec 2024', 
      type: 'all',
      bgColor: 'bg-indigo-500',
      textColor: 'text-indigo-600'
    },
    { 
      id: 'AT', 
      name: 'Aryaman Thakur', 
      email: 'aryaman.thakur999888@gmail.com',
      lastSession: '5th Jul 2024', 
      type: 'new',
      bgColor: 'bg-blue-500',
      textColor: 'text-blue-600',
      isNew: true
    },
    { 
      id: 'NN', 
      name: 'Nose therapy name', 
      email: 'therapy@example.com',
      lastSession: '27th Mar 2024', 
      type: 'all',
      bgColor: 'bg-cyan-500',
      textColor: 'text-cyan-600'
    },
    { 
      id: 'OM', 
      name: 'Omar', 
      email: 'omar@example.com',
      lastSession: '6th Mar 2024', 
      type: 'new',
      bgColor: 'bg-teal-500',
      textColor: 'text-teal-600',
      isNew: true
    }
  ];

  const navItems = [
    { id: 'new', label: 'New Leads', icon: Sparkles },
    { id: 'all', label: 'All Clients', icon: Users }
  ];

  const filteredClients = clients.filter(client => 
    client.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (activeNav === 'all' ? true : client.type === activeNav)
  );

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      {/* Gradient Background Decoration */}
      <div className="absolute top-0 left-0 w-full h-48 bg-gradient-to-b from-blue-500/10 via-sky-500/5 to-transparent -z-10" />
      
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
        <div className="flex items-center gap-6">
          <div className="p-2 bg-blue-100 rounded-lg">
            <Users className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Client</h1>
            {/* <p className="text-sm text-gray-500 mt-1">You can check all your clients here</p> */}
          </div>
        </div>
        <button className="flex items-center gap-2 px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 shadow-md hover:shadow-lg transition-all border-0">
          <UserPlus size={18} />
          <span>Add Client</span>
        </button>
      </div>

      {/* Search and Navigation */}
      <div className="mb-6 space-y-10">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            placeholder="Search by name..."
            className="w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <nav className="flex gap-4 border-b border-gray-200">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <button
                key={item.id}
                onClick={() => setActiveNav(item.id)}
                className={`flex items-center gap-2 px-4 py-2 text-sm font-medium border-b-2 -mb-px transition-all ${
                  activeNav === item.id
                    ? 'border-blue-500 text-blue-600 bg-blue-50'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                <Icon size={18} />
                {item.label}
              </button>
            );
          })}
        </nav>
      </div>

 {/* Client List */}
 <div className="overflow-hidden border-y border-gray-200">
        {/* Column Headers */}
        <div className="grid grid-cols-[40px,240px,1fr,180px,80px] gap-4 py-3 px-4 border-b border-gray-200">
          <div></div>
          <div className="text-sm font-medium text-gray-500">Name</div>
          <div className="text-sm font-medium text-gray-500">Email</div>
          <div className="text-sm font-medium text-gray-500">Last Session</div>
          <div></div>
        </div>

         {/* Client List */}
         <div className="divide-y divide-gray-200">
          {filteredClients.map((client) => (
            <div 
              key={client.id} 
              className="group grid grid-cols-[40px,240px,1fr,180px,80px] gap-4 px-4 hover:bg-blue-50 transition-colors items-center h-14 cursor-pointer"
              onClick={() => navigate('/clients/lead-chart')}
            >
              {/* Avatar */}
              <div className="relative flex items-center">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-medium ${client.bgColor}`}>
                  {client.name.charAt(0)}
                </div>
              </div>

              {/* Name */}
              <div className="flex items-center min-w-0">
                <span className={`font-medium text-base truncate ${client.textColor}`}>
                  {client.name}
                </span>
              </div>

              {/* Email */}
              <div className="flex items-center gap-2 min-w-0">
                <Mail className="w-4 h-4 flex-shrink-0 text-gray-400" />
                <span className="text-base text-gray-600 truncate">{client.email}</span>
              </div>

              {/* Last Session */}
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 flex-shrink-0 text-gray-400" />
                <span className="text-base text-gray-600">{client.lastSession}</span>
              </div>

              {/* Action */}
              <div className="flex justify-end">
                <div className="text-blue-600 opacity-0 group-hover:opacity-100 transition-opacity flex items-center gap-1">
                  <ArrowRight className="w-5 h-5" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientList;