import AllSessions from "../../../report/components/allSessions/AllSessions";
import Files from "../../../report/components/files/Files";
import Tasks from "../../../report/components/tasks/Tasks";
import NotesTabPanel from "./clientTabPanels/notesTabPanel";
import OverviewTabPanel from "./clientTabPanels/overviewTabPanel";
import Assessments from "./assessments/Assessments";
import "./clientUtilitiesTab.css";

import { useState } from "react";

const TabTitle = function (props) {
  const activeState = {
    color: "#282860",
    opacity: "1",
  };

  const inactiveState = {
    color: "#282860",
    opacity: "0.5",
  };

  const activeStateBottom = {
    height: "1px",
    backgroundColor: "#282860",
  };

  const inactiveStateBottom = {
    height: "1px",
  };

  return (
    <div>
      <div
        className="tab-title"
        style={props.activeIdx === props.idx ? activeState : inactiveState}
        onClick={() => props.updateTabIndex(props.idx)}
      >
        {props.title}
      </div>
      <div
        className="tab-bottom"
        style={
          props.activeIdx === props.idx
            ? activeStateBottom
            : inactiveStateBottom
        }
      ></div>
    </div>
  );
};

const TabList = function (props) {
  return (
    <div className="tab-list">
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Overview"}
        idx={0}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Notes"}
        idx={1}
        updateTabIndex={props.updateTabIndex}
      />
      {/* <TabTitle
        activeIdx={props.activeIdx}
        title={"Tasks"}
        idx={2}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Assessments"}
        idx={3}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Files"}
        idx={4}
        updateTabIndex={props.updateTabIndex}
      /> */}
      <TabTitle
        activeIdx={props.activeIdx}
        title={"All Session Data"}
        idx={5}
        updateTabIndex={props.updateTabIndex}
      />
    </div>
  );
};

let ClientUtilitiesTab = function ({ realtimeButton }) {
  let [tabIndex, setTabIndex] = useState(0);
  function updateTabIndex(idx) {
    setTabIndex(idx);
  }

  return (
    <div className="tabs-container">
      <TabList updateTabIndex={updateTabIndex} activeIdx={tabIndex} />
      <div className="tab-panel scroll-thin-vertical">
        {tabIndex === 0 && <OverviewTabPanel updateTabIndex={updateTabIndex} />}
        {tabIndex === 1 && <NotesTabPanel realtimeButton={realtimeButton} />}
        {tabIndex === 2 && <Tasks />}
        {tabIndex === 3 && <Assessments />}
        {tabIndex === 4 && <Files />}
        {tabIndex === 5 && <AllSessions />}
      </div>
    </div>
  );
};

export default ClientUtilitiesTab;
