import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/card";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer, BarChart, Bar,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart,
  Radar
} from 'recharts';
import {
  Brain, Heart, MessageCircle, Zap, TrendingUp,
  Calendar, Clock, AlertCircle,
  Shield,
  Star,
  Search,
  Info
} from 'lucide-react';


const CustomTooltip = ({ active, payload, label, onInvestigate }) => {
  if (active && payload && payload.length) {
    const handleClick = (e) => {
      console.log("click detected");
      if (onInvestigate) {
        onInvestigate(Number(label));
      }
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
        <div className="mb-3">
          <p className="font-medium text-gray-900">Session {label}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm text-gray-600">
              {entry.name}: {Math.round(entry.value)}%
            </p>
          ))}
        </div>
        <button
          className="w-full px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center justify-center gap-2 transition-colors"
          onClick={handleClick}
        >
          <Search className="h-4 w-4" />
          Investigate Session
        </button>
      </div>
    );
  }
  return null;
};

const ProgressChart = ({ data, onInvestigate }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Progress Over Time</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="session" />
              <YAxis domain={[0, 100]} />
              <Tooltip
                content={<CustomTooltip onInvestigate={onInvestigate} />}
                wrapperStyle={{ pointerEvents: 'auto' }}

              />
              <Legend />
              <Line
                type="monotone"
                dataKey="empathy"
                stroke="#3B82F6"
                name="Empathy"
                strokeWidth={2}
                dot={{ r: 6, strokeWidth: 2, fill: "white" }}
                activeDot={{ r: 8, strokeWidth: 2 }}
              />
              <Line
                type="monotone"
                dataKey="engagement"
                stroke="#60A5FA"
                name="Engagement"
                strokeWidth={2}
                dot={{ r: 6, strokeWidth: 2, fill: "white" }}
                activeDot={{ r: 8, strokeWidth: 2 }}
              />
              <Line
                type="monotone"
                dataKey="communication"
                stroke="#93C5FD"
                name="Communication"
                strokeWidth={2}
                dot={{ r: 6, strokeWidth: 2, fill: "white" }}
                activeDot={{ r: 8, strokeWidth: 2 }}
              />
              <Line
                type="monotone"
                dataKey="intervention"
                stroke="#BFDBFE"
                name="Intervention"
                strokeWidth={2}
                dot={{ r: 6, strokeWidth: 2, fill: "white" }}
                activeDot={{ r: 8, strokeWidth: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

const tooltipInfo = {
  empathy: "Reflects how well the therapist recognized and responded to the client’s emotions.",
  engagement: "Shows how much the therapist’s statement captured and sustained the client’s interest.",
  communication: "Indicates clarity and effectiveness of the therapist’s language and message delivery.",
  intervention: "Measures how effectively the therapist’s statement facilitated deeper exploration or change."
};


const ClientView = ({ clientName, clientData, onSessionSelect }) => {

  if (!clientData) {
    return <div>Loading...</div>;
  }

  const {
    therapy_name,
    avg_empathy,
    avg_engagement,
    avg_communication,
    avg_intervention,
    avg_compatibility_sub_scores,
    avg_compatibility_scores,
    session_count,
    latest_session_datetime,
    areas_of_improvement,
    reasons,
    sessions
  } = clientData;

  // Sample trend data for the summary charts
  // const trendData = [
  //   { session: 1, empathy: 82, engagement: 75, communication: 70, intervention: 65 },
  //   { session: 2, empathy: 85, engagement: 78, communication: 73, intervention: 68 },
  //   { session: 3, empathy: 87, engagement: 80, communication: 75, intervention: 72 },
  //   { session: 4, empathy: 89, engagement: 83, communication: 78, intervention: 75 }
  // ];

  // trendData for the chart
  const trendData = sessions.map((s, index) => ({
    session: index + 1,
    empathy: s.empathy,
    engagement: s.engagement,
    communication: s.communication,
    intervention: s.intervention
  }));


  // const compatibilitySubScores = [
  //   { name: 'Qualifications', score: 8 },
  //   { name: 'Client Well-being', score: 9 },
  //   { name: 'Goals Exploration', score: 7 },
  //   { name: 'Active Listening', score: 9 },
  //   { name: 'Strategy Suggestions', score: 6 },
  //   { name: 'Validation', score: 9 },
  //   { name: 'Collaboration', score: 8 },
  //   { name: 'Past Experience', score: 7 },
  //   { name: 'Daily Routine', score: 6 },
  //   { name: 'Psychoeducation', score: 7 }
  // ];

  const SUBSCORE_LABELS = {
    qualifications_and_introduction: "Qualifications",
    psychoeducation: "Psychoeducation",
    exploration_of_clients_daily_routine: "Daily Routine",
    exploration_of_clients_past_therapy_experience: "Past Experience",
    collaboration_and_flexibility: "Collaboration",
    validation_and_normalization: "Validation",
    suggestions_and_strategies: "Strategy Suggestions",
    active_listening_and_reflection: "Active Listening",
    exploration_of_clients_goals: "Goals Exploration",
    inquiry_about_clients_well_being: "Client Well-being"
  };

  const compatibilitySubScores = Object.entries(avg_compatibility_sub_scores || {}).map(([key, val]) => {
    const displayName = SUBSCORE_LABELS[key] || key;
    return {
      name: displayName,
      score: val
    };
  });

  // const areasOfImprovement = [
  //   {
  //     suggestion: "Involve client more in defining specific goals for therapy",
  //     excerpt: "Therapist: 'What would you like to focus on today?' Client: 'I'm not sure yet.'"
  //   },
  //   {
  //     suggestion: "Provide clearer strategies to address challenges",
  //     excerpt: "Therapist: 'You might find journaling helpful.' Client: 'I've tried it before, but it didn't work for me.'"
  //   },
  //   {
  //     suggestion: "Explore daily routine in greater detail",
  //     excerpt: "Therapist: 'Can you tell me about your typical day?' Client: 'It's just work and home stuff.'"
  //   }
  // ];

  const handleSessionClick = (session) => {
    if (onSessionSelect) {
      onSessionSelect(session.date);
    }
  };

  const handleInvestigate = (session) => {
    console.log("Investigating session:", session);
    // Find the session data that corresponds to this session number
    const sessionDate = sessionData[session - 1]?.date;
    console.log("Found session date:", sessionDate);
    if (sessionDate && onSessionSelect) {
      onSessionSelect(sessionDate);
    }
  };
  // Sample session data
  const sessionData = [
    {
      date: '2024-01-20',
      duration: '50 mins',
      empathy: 85,
      engagement: 78,
      communication: 73,
      intervention: 68,
      topics: ['Anxiety', 'Work Stress', 'Family'],
      status: 'Completed'
    },
    {
      date: '2024-01-27',
      duration: '55 mins',
      empathy: 87,
      engagement: 80,
      communication: 75,
      intervention: 72,
      topics: ['Anxiety', 'Relationships'],
      status: 'Completed'
    },
    {
      date: '2024-02-03',
      duration: '45 mins',
      empathy: 89,
      engagement: 83,
      communication: 78,
      intervention: 75,
      topics: ['Work Stress', 'Self-care'],
      status: 'Completed'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Client Summary Header */}
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{therapy_name}</h2>
          <p className="text-gray-600">Session History and Progress</p>
        </div>
        <div className="flex items-center gap-2">
          <Clock className="h-5 w-5 text-blue-500" />
          <span className="text-gray-600">
            Last Session: {latest_session_datetime?.substring(0, 10) || "N/A"}
          </span>
        </div>
      </div>

      {/* Summary Metrics */}
      <div className="grid grid-cols-4 gap-4">
        <Card className="relative">
          <CardContent className="pt-6">
            <div className="group absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-600">
              <Info className="h-5 w-5" />
              <div className="hidden group-hover:block absolute top-6 right-0 bg-white border border-gray-200 p-2 rounded shadow-md text-sm w-48">
                {tooltipInfo.empathy}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Heart className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm font-medium text-gray-600 my-0">Avg Empathy</p>
                <h3 className="text-2xl font-bold text-blue-600 my-0">{Math.round(avg_empathy)}%</h3>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="relative">
          <CardContent className="pt-6">
            <div className="group absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-600">
              <Info className="h-5 w-5" />
              <div className="hidden group-hover:block absolute top-6 right-0 bg-white border border-gray-200 p-2 rounded shadow-md text-sm w-48">
                {tooltipInfo.engagement}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <MessageCircle className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm font-medium text-gray-600 my-0">Avg Engagement</p>
                <h3 className="text-2xl font-bold text-blue-600 my-0">{Math.round(avg_engagement)}%</h3>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="relative">
          <CardContent className="pt-6">
            <div className="group absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-600">
              <Info className="h-5 w-5" />
              <div className="hidden group-hover:block absolute top-6 right-0 bg-white border border-gray-200 p-2 rounded shadow-md text-sm w-48">
                {tooltipInfo.communication}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Brain className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm font-medium text-gray-600 my-0">Avg Communication</p>
                <h3 className="text-2xl font-bold text-blue-600 my-0">{Math.round(avg_communication)}%</h3>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="relative">
          <CardContent className="pt-6">
            <div className="group absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-600">
              <Info className="h-5 w-5" />
              <div className="hidden group-hover:block absolute top-6 right-0 bg-white border border-gray-200 p-2 rounded shadow-md text-sm w-48">
                {tooltipInfo.intervention}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Zap className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm font-medium text-gray-600 my-0">Avg Intervention</p>
                <h3 className="text-2xl font-bold text-blue-600 my-0">{Math.round(avg_intervention)}%</h3>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <ProgressChart
        data={trendData}
        onInvestigate={(sessionIndex) => {
          console.log("sessionIndex", sessionIndex)

          const targetSession = sessions[sessionIndex - 1];
          console.log("targetSession", targetSession)
          if (!targetSession) return;
          if (onSessionSelect) {
            onSessionSelect(targetSession.session_id);
          }
        }}

      />


      <div className="grid grid-cols-2 gap-6">
        {/* Overall Compatibility */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Shield className="h-5 w-5 text-blue-500" />
              Average Compatibility Score
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between mb-6">
              <div className="text-center">
                <div className="text-4xl font-bold text-blue-600">{Math.round(avg_compatibility_scores)}%</div>
                <p className="text-sm text-gray-600 mt-1">Therapeutic Alignment</p>
              </div>
              <div className="text-sm text-gray-600 max-w-xs">
                {clientData.reasons || ""}
              </div>
            </div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart data={compatibilitySubScores}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="name" />
                  <PolarRadiusAxis domain={[0, 10]} />
                  <Radar
                    name="Score"
                    dataKey="score"
                    stroke="#3B82F6"
                    fill="#3B82F6"
                    fillOpacity={0.5}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        {/* Areas of Improvement */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Star className="h-5 w-5 text-blue-500" />
              Areas of Improvement
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {(areas_of_improvement || []).map((area, index) => (
                <div key={index} className="bg-blue-50 p-4 rounded-lg">
                  <h4 className="font-medium text-blue-900 my-1">{area.title}</h4>
                  <p className="text-sm text-gray-600 italic my-0">"{area.example_script}"</p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Session History Table */}
      <Card>
        <CardHeader>
          <CardTitle>Session History</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto">
            {/* table-layout: auto にし、全体を分割線で区切る */}
            <table className="w-full table-auto">
              <thead className="border-b">
                <tr className="border-b">
                  {/* Date カラムに whitespace-nowrap とある程度の幅を指定 */}
                  <th className="text-left py-3 px-4 font-medium text-gray-600 whitespace-nowrap w-32">
                    Date
                  </th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Duration</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Empathy</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Engagement</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Communication</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Intervention</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Topics</th>
                  <th className="text-left py-3 px-4 font-medium text-gray-600">Status</th>
                </tr>
              </thead>
              <tbody>
                {[...sessions]
                  .sort((a, b) => {
                    const aDate = a.start_datetime || a.end_datetime;
                    const bDate = b.start_datetime || b.end_datetime;
                    const aMs = aDate ? new Date(aDate).getTime() : 0;
                    const bMs = bDate ? new Date(bDate).getTime() : 0;
                    return bMs - aMs;
                  })
                  .map((session, index) => {
                    const dateLabel =
                      session.start_datetime?.substring(0, 10) ||
                      session.end_datetime?.substring(0, 10) ||
                      `Session ${index + 1}`;

                    return (
                      <tr
                        key={session.session_id}
                        className="border-b hover:bg-gray-50 cursor-pointer transition-colors"
                        onClick={() => onSessionSelect && onSessionSelect(session.session_id)}
                      >
                        <td className="py-3 px-4 whitespace-nowrap">
                          {dateLabel}
                        </td>
                        <td className="py-3 px-4">
                          {session.duration ? `${Math.round(session.duration)} mins` : "--"}
                        </td>
                        <td className="py-3 px-4">{Math.round(session.empathy)}%</td>
                        <td className="py-3 px-4">{Math.round(session.engagement)}%</td>
                        <td className="py-3 px-4">{Math.round(session.communication)}%</td>
                        <td className="py-3 px-4">{Math.round(session.intervention)}%</td>
                        <td className="py-3 px-4">
                          <div className="flex gap-2 flex-wrap">
                            {(session.topics_covered || []).map(topic => (
                              <span
                                key={topic}
                                className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                              >
                                {topic}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="py-3 px-4">
                          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">
                            Completed
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientView;