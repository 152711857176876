import { useState, useEffect, useRef } from "react";
import ASSETS from "../../../../../assets/Assets";
import "./RealtimePanel.css";

import Popup from "reactjs-popup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IconButton } from "@mui/material";

import {
  highlightsAndQuestionsFromTranscript,
  getPreviousSessionHighlights,
  generateClientSentimentScoreAPI,
  determineRoleFromTranscript,
} from "../../../../../api/realtime";

import TranscriptPanelImg from "../Assets/TranscriptPanelImg.png";
import SoundIcon from "../Assets/soundWave.png";
import HistogramImg from "../Assets/histogram.png";
import TranscriptPause from "../Assets/pause.svg";
import TranscriptStop from "../Assets/stop.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientSentimentScore,
  setTranscript,
  setMicrophoneStatus,
  setTranscriptArr,
  setSpeakerLabel,
} from "../../../../../store/liveSession/liveSessionSlice";
import { Buffer } from "buffer";
import * as process from "process";
import { getTranscribePresignedURL } from "../../../../../api/audioTranscription";
import { EventStreamMarshaller } from "@aws-sdk/eventstream-marshaller";
import { fromUtf8, toUtf8, toIs } from "@aws-sdk/util-utf8-node";
import MicrophoneStream from "microphone-stream";
import { FaPlay } from "react-icons/fa";

import { LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useStopwatch } from "react-timer-hook";
import {
  stripTimestampFromTranscript,
  aggregateTimestamps,
  generateTranscriptTextFromArray,
} from "./TranscriptPanelUtls";

window.process = process;
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const SAMPLE_RATE = 44100;
let sampleRate = SAMPLE_RATE;
const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "20px 20px",
  height: "100%",
};

const chipStyle = {
  padding: "5px 16px",
  borderRadius: "6px",
  background: "#C9CEFA",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#282860",
  fontSize: "12px",
  userSelect: "none",
  cursor: "pointer",
};

const soundIconStyle = {
  width: "70px",
  height: "auto",
  objectFit: "cover",
  cursor: "pointer",
  marginBottom: "30px",
};

const histogramImgStyle = {
  height: "auto",
  objectFit: "cover",
  width: "100%",
  marginBottom: "30px",
};

const playPauseIconStyle = {
  height: "auto",
  objectFit: "cover",
  width: "100%",
  cursor: "pointer",
};

const RealtimePanelInactiveComponent = ({ startRecording }) => {
  return (
    <div className="realtime-transcript-panel-container">
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "50px",
        }}
      >
        <span className="transcript-container-text">
          <span>Press below to</span>
          <span>start recording session for AI assistance</span>
        </span>

        <img
          style={{
            width: "70px",
            height: "auto",
            objectFit: "cover",
            cursor: "pointer",
          }}
          src={SoundIcon}
          alt="Realtime Session"
          className="realtime-button-mic-icon"
          onClick={() => startRecording()}
        />

        <div
          style={{
            width: "100%",
          }}
        >
          <img
            src={TranscriptPanelImg}
            alt="Realtime Session"
            className="realtime-button-mic-icon"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const FollowUpQuestionView = ({ highlights, clickedChip }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <p>Follow-up Question Based On Highlights</p>
        <Popup
          trigger={(open) => (
            <IconButton>
              <ErrorOutlineIcon />
            </IconButton>
          )}
          position="bottom center"
          closeOnDocumentClick
          on={["hover", "focus"]}
        >
          <div className="popup-body-content">
            Live highlights are generated from real-time conversations with
            patients to better curate follow-up questions based on their
            feedback.
          </div>
        </Popup>
      </div>

      <div
        style={{
          flex: 1,
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        <div
          className="scroll-thin-vertical"
          style={{
            ...containerStyleBasic,
            overflowX: "hidden",
          }}
        >
          {highlights[clickedChip]?.questions.map((question, index) => (
            <div
              key={index}
              style={{
                margin: "0px",
                marginBottom: "20px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                padding: "0px",
                paddingBottom: "15px",

                color: "#282860",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "normal",
                opacity: 0.8,
              }}
            >
              {question}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const downsampleBuffer = (
  buffer,
  inputSampleRate = SAMPLE_RATE,
  outputSampleRate = 16000
) => {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
};

const getAudioEventMessage = (buffer) => {
  return {
    headers: {
      ":message-type": {
        type: "string",
        value: "event",
      },
      ":event-type": {
        type: "string",
        value: "AudioEvent",
      },
    },
    body: buffer,
  };
};

export const pcmEncode = (input) => {
  var offset = 0;
  var buffer = new ArrayBuffer(input.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};

// -------- If we use normal conversation -----------------
// const createTranscript = (transcriptionData) => {
//   let transcript = '';
//   let currentSpeaker = '';

//   transcriptionData.forEach((entry) => {
//     if (entry.Type === 'punctuation') {
//       transcript += `${entry.Content}`;
//       return; // Skip further processing for punctuation entries
//     }
//     const speaker = entry.Speaker ? `Speaker ${entry.Speaker}` : 'Unknown Speaker';

//     if (currentSpeaker !== speaker) {
//       // Add speaker information only when it changes
//       transcript += `\n${speaker}: `;
//       currentSpeaker = speaker;
//     }

//     const content = entry.Content || '';

//     transcript += ` ${content}`;
//   });

//   return transcript.trim();
// }

// For AWS medical transcribe with type as conversation

const ChipView = ({ highlights, clickedChip, newHighlights, onChipClick }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <p>Live Highlights</p>
        <Popup
          trigger={(open) => (
            <IconButton>
              <ErrorOutlineIcon />
            </IconButton>
          )}
          position="bottom center"
          closeOnDocumentClick
          on={["hover", "focus"]}
        >
          <div className="popup-body-content">
            Live highlights are generated from real-time conversations with
            patients to better curate follow-up questions based on their
            feedback.
          </div>
        </Popup>
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        <div
          className="scroll-thin-vertical"
          style={{
            ...containerStyleBasic,
            display: "flex",
            gap: "5px",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            overflowX: "hidden",
          }}
        >
          {highlights.map((item, index) => (
            <span
              key={index}
              style={{
                ...chipStyle,
                // background: clickedChip === index ? "#5F6CE1" : "#C9CEFA",
                background:
                  clickedChip === index
                    ? "#5F6CE1"
                    : highlights.includes(item) && newHighlights.includes(item)
                    ? "rgba(236, 116, 64, 0.20)"
                    : "#C9CEFA",
                color: clickedChip === index ? "#FFFFFF" : "#282860",
                border:
                  highlights.includes(item) && newHighlights.includes(item)
                    ? "1px solid #EC7440"
                    : "",
              }}
              onClick={() => onChipClick(index)}
            >
              {item.highlights}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

const RealtimePanelActiveComponent = ({
  highlights,
  clickedChip,
  newHighlights,
  handleChipClick,
  recorder,
  onRecordingStop,
  recordingPaused,
  onViewTranscriptClick,
  onSessionStartClick,
  onRecordingPause,
  onRecordingResume,
  transcriptViewPanel,
}) => {
  return (
    <div
      className="realtime-transcript-panel-container"
      style={{ paddingBottom: "30px" }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <img
          style={soundIconStyle}
          src={SoundIcon}
          alt="Realtime Session"
          className="realtime-button-mic-icon"
          onClick={onSessionStartClick}
        />

        {/* <img
          style={histogramImgStyle}
          src={HistogramImg}
          alt="Realtime Session"
          className="realtime-button-mic-icon"
        /> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "75px",
            marginBottom: "10px",
          }}
        >
          {recorder.mediaRecorder && (
            <LiveAudioVisualizer
              mediaRecorder={recorder.mediaRecorder}
              width={370}
              height={75}
            />
          )}
          <div style={{ display: "none" }}>
            {!recorder.mediaRecorder && (
              <AudioRecorder recorderControls={recorder} />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {/* {!recordingPaused && (
              <img
                style={playPauseIconStyle}
                src={TranscriptPause}
                alt="Realtime Session"
                className="realtime-button-mic-icon"
                onClick={onRecordingPause}
              />
            )}

            {recordingPaused && (
              <FaPlay
                style={playPauseIconStyle}
                alt="Realtime Session"
                className="realtime-button-mic-icon"
                onClick={onRecordingResume}
              />
            )}*/}

            {/* <img
              style={playPauseIconStyle}
              src={TranscriptStop}
              alt="Realtime Session"
              className="realtime-button-mic-icon"
              onClick={onRecordingStop}
            /> */}

            <div
              className={`${
                recordingPaused ? "play-button" : "pause-button"
              } button`}
              onClick={() => {
                recordingPaused ? onRecordingResume() : onRecordingPause();
              }}
            >
              {recordingPaused ? (
                <img src={ASSETS.playIconWhite} alt="play transcription" />
              ) : (
                <img src={ASSETS.pauseBlack} alt="pause transcription" />
              )}
              {recordingPaused ? "Play" : "Pause"}
            </div>
          </div>
          <div
            className="view-live-transcription"
            onClick={onViewTranscriptClick}
          >
            {transcriptViewPanel ? (
              <div className="view-transcript-button">
                <img src={ASSETS.leftArrowBlue} alt="live transcript" />
                <span>View Client Overview</span>
              </div>
            ) : (
              <div className="view-transcript-button">
                <span>View Live Transcript</span>
                <img src={ASSETS.rightArrowBlue} alt="live transcript" />
              </div>
            )}
          </div>
        </div>
        <hr
          style={{
            width: "100%",
            opacity: "0.5",
          }}
        />

        <ChipView
          highlights={highlights}
          clickedChip={clickedChip}
          newHighlights={newHighlights}
          onChipClick={handleChipClick}
        />
        <FollowUpQuestionView
          highlights={highlights}
          clickedChip={clickedChip}
        />
      </div>
    </div>
  );
};

const replaceMultipleStrings = (targetString, replacements) => {
  for (let role in replacements) {
    if (replacements.hasOwnProperty(role)) {
      replacements[role].forEach((oldString) => {
        let newString = role; // Replace this with your desired replacement
        targetString = targetString.split(oldString).join(newString);
      });
    }
  }
  return targetString;
};

const RealtimePanel = ({
  onViewTranscriptClick,
  onSessionStopClick,
  realtimeButtonState,
  transcriptViewPanel,
  audioChunksRef,
}) => {
  const [sessionStartButton, setSessionStartButton] = useState(false);
  const [clickedChip, setClickedChip] = useState(-1);

  const microphoneStreamRef = useRef(undefined);
  const inputSampleRateRef = useRef(undefined);
  const socketRef = useRef(undefined);
  const dispatch = useDispatch();
  const transcript = useSelector((store) => store.liveSession.transcript);
  const transcriptArr = useSelector((store) => store.liveSession.transcriptArr);
  const transcriptLang = useSelector(
    (store) => store.liveSession.transcriptLang
  );
  const [recordingPaused, setRecordingPaused] = useState(false);

  const transcriptLineCountRef = useRef(0);
  const TRANSCRIPT_LINE_COUNT_THRESHOLD = 3;

  const [highlights, setHighlights] = useState([]);
  const [newHighlights, setNewHighlights] = useState([]);

  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });

  const speakerLabel = useSelector((store) => {
    return store.liveSession.speakerLabel;
  });
  const speakerLabelRef = useRef(speakerLabel);
  const lastSpeakerRef = useRef("");
  const currentSentenceRef = useRef("");
  const currentStartTimeRef = useRef(0);
  const currentEndTimeRef = useRef(0);
  const speakerTimeoutRef = useRef(null);

  useEffect(() => {
    speakerLabelRef.current = speakerLabel;
  }, [speakerLabel]);

  const stopWatch = useStopwatch();
  const pausedRef = useRef(false);
  const pausedOffset = useRef(0);

  const recorder = useAudioRecorder();

  // const generateClientTranscript = (newTranscript) => {
  //   const currentTranscripts = newTranscript.split("\n");
  //   if (
  //     !speakerLabelRef.current ||
  //     !speakerLabelRef.current.client ||
  //     speakerLabelRef.current?.client?.length === 0
  //   )
  //     return;
  //   let clientTranscripts = "";
  //   currentTranscripts.forEach((transcript) => {
  //     if (
  //       speakerLabelRef.current.client.some((label) =>
  //         transcript.startsWith(label)
  //       )
  //     ) {
  //       clientTranscripts += transcript + "\n";
  //     }
  //   });
  //   return clientTranscripts;
  // };

  useEffect(() => {
    console.log("turn on the mic", realtimeButtonState);

    if (!realtimeButtonState) {
      console.log("turn off the mic");
      stopRecording();
      recorder?.stopRecording();
      dispatch(setMicrophoneStatus(false));
    }

    return () => {
      console.log("turn off the mic");
      stopRecording();
      recorder?.stopRecording();
    };
  }, [realtimeButtonState]);

  useEffect(() => {
    if (
      transcriptArr &&
      transcriptArr.length > 0 &&
      transcriptArr.length - transcriptLineCountRef.current >=
        TRANSCRIPT_LINE_COUNT_THRESHOLD
    ) {
      transcriptLineCountRef.current = transcriptArr.length;
      highlightsAndQuestionsFromTranscript(
        therapist_id,
        generateTranscriptTextFromArray(transcriptArr, speakerLabel)
      )
        .then((res) => {
          const highlight_data = res.data;
          highlight_data.forEach((obj) => {
            if (obj.hasOwnProperty("highlight")) {
              obj.highlight = obj.highlights;
              delete obj.highlights;
            }
          });

          const newHighlights = res.data;
          // console.log(newHighlights);

          setHighlights((prevHighlights) => [
            ...prevHighlights,
            ...newHighlights,
          ]);
          setNewHighlights(newHighlights);
        })
        .catch((err) => {
          console.error(err);
        });

      determineRoleFromTranscript(
        therapist_id,
        generateTranscriptTextFromArray(transcriptArr)
      )
        .then((res) => {
          // console.log("Diarized data: ", res.data)
          dispatch(setSpeakerLabel(res.data));
        })
        .catch((err) => {
          console.log("error is : ", err);
        });
    }
  }, [transcriptArr]);

  const handleChipClick = (index) => {
    setClickedChip(index);
  };

  const stopRecording = () => {
    if (microphoneStreamRef.current) {
      console.log("Recording stopped");
      microphoneStreamRef.current.stop();
      microphoneStreamRef.current.destroy();
      microphoneStreamRef.current = undefined;
      setSessionStartButton(false);
    }
    recorder?.stopRecording();
  };

  const startRecording = async () => {
    recorder.startRecording();
    if (microphoneStreamRef.current) {
      stopRecording();
      recorder?.stopRecording();
      return;
    }
    await createSocketConnection();
    microphoneStreamRef.current = new MicrophoneStream();
    microphoneStreamRef.current.on("format", (data) => {
      inputSampleRateRef.current = data.sampleRate;
    });
    microphoneStreamRef.current.setStream(
      await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
        },
      })
    );
    setSessionStartButton(true);
    dispatch(setMicrophoneStatus(true));
  };

  const createSocketConnection = async () => {
    socketRef.current = new WebSocket(
      // `ws://localhost:5001/listen/aws-transcribe?language=${transcriptLang}`
      `wss://websocket.therapist.therawin.health/listen/aws-transcribe?language=${transcriptLang}`
      // `wss://websocket.therapist.therawin.health/listen/aws-transcribe?language=en-US`
    );
    // socketRef.current.onopen = function () {
    //   if (socketRef.current.readyState === socketRef.current.OPEN) {
    //     microphoneStreamRef.current.on("data", function (rawAudioChunk) {
    //       let binary = convertAudioToBinaryMessage(rawAudioChunk);
    //       socketRef.current.send(binary);
    //     });
    //   }
    // };

    // socketRef.current.onmessage = function (message) {
    //   let messageWrapper = eventStreamMarshaller.unmarshall(
    //     Buffer(message.data)
    //   );
    //   console.log(messageWrapper);
    //   let utf8decoder = new TextDecoder();
    //   let messageBody = JSON.parse(utf8decoder.decode(messageWrapper.body));
    //   console.log(messageBody);
    //   if (messageWrapper.headers[":message-type"].value === "event") {
    //     let results = messageBody.Transcript?.Results;
    //     if (results.length && !results[0]?.IsPartial) {
    //       const newTranscript = createTransriptUsingArr(
    //         results[0].Alternatives[0]
    //       );
    //     }
    //   }
    // };

    // socketRef.current.onerror = function (error) {
    //   console.log("WebSocket connection error. Try again.", error);
    // };
    socketRef.current.onmessage = async function (message) {
      const data = JSON.parse(message.data);
      console.log(data);
      if (data.event === "aws-connected") {
        microphoneStreamRef.current.on("data", function (rawAudioChunk) {
          let binary = convertAudioToBinaryMessage(rawAudioChunk);
          socketRef.current.send(binary);
          audioChunksRef.current.push(binary);
        });
      } else {
        const newTranscript = createTranscript(data);
      }
    };

    socketRef.current.onerror = function (error) {
      console.log("WebSocket connection error. Try again.", error);
    };
  };

  const generateSentimentScore = () => {
    if (
      currentSentenceRef.current &&
      currentStartTimeRef.current !== currentEndTimeRef.current
    ) {
      if (
        speakerLabelRef.current?.client?.length > 0 &&
        speakerLabelRef.current.client.some(
          (label) => label === lastSpeakerRef.current
        )
      ) {
        generateClientSentimentScoreAPI(
          stripTimestampFromTranscript(currentSentenceRef.current),
          currentEndTimeRef.current,
          currentStartTimeRef.current
        )
          .then((res) => {
            const data = res.data;
            dispatch(
              addClientSentimentScore({
                time: data.time - pausedOffset.current,
                score: data.score,
              })
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  // const createTransriptUsingArr = (transcriptionData) => {
  //   if (transcriptionData.Items.length === 0) return;
  //   console.log(
  //     "---------------------Trabsdkas===============",
  //     transcriptionData
  //   );
  //   let currArr = [];
  //   let currentSpeaker = lastSpeakerRef.current;
  //   transcriptionData.Items.forEach((entry) => {
  //     if (entry.Type === "speaker-change") return;
  //     if (entry.Type === "punctuation") {
  //       if (currArr.length !== 0) {
  //         currArr[currArr.length - 1].content += entry.Content;
  //       }
  //       return;
  //     }
  //     const speaker = `Speaker ${entry.Speaker}`;
  //     if (speakerTimeoutRef.current) clearTimeout(speakerTimeoutRef.current);
  //     speakerTimeoutRef.current = setTimeout(() => {
  //       console.log("-------------timeout called--------------------");
  //       generateSentimentScore();
  //       lastSpeakerRef.current = "";
  //       currentSentenceRef.current = "";
  //       currentStartTimeRef.current = 0;
  //       currentEndTimeRef.current = 0;
  //     }, 10 * 1000);
  //     if (currentSpeaker !== speaker) {
  //       // Add speaker information only when it changes
  //       currArr.push({
  //         speaker,
  //         content: entry.Content,
  //         start: entry.StartTime - pausedOffset.current,
  //         end: entry.EndTime - pausedOffset.current,
  //         type: "speaker-change",
  //       });
  //       generateSentimentScore();
  //       currentSpeaker = speaker;
  //       lastSpeakerRef.current = speaker;
  //       currentSentenceRef.current = `\n${speaker}:`;
  //       currentStartTimeRef.current = entry.start;
  //     } else {
  //       currArr.push({
  //         content: entry.Content,
  //         end: entry.EndTime - pausedOffset.current,
  //         type: "same-speaker",
  //       });
  //     }
  //     currentEndTimeRef.current = entry.end;
  //     currentSentenceRef.current += " " + entry.sentence;
  //   });
  //   console.log(currArr);
  //   dispatch(setTranscriptArr(currArr));
  // };

  const createTranscript = (transcriptionData) => {
    let currentSpeaker = lastSpeakerRef.current;
    if (transcriptionData.length === 0) return;
    let currArr = [];

    transcriptionData.forEach((entry) => {
      console.log(entry);
      const speaker = `Speaker ${entry.speaker}`;

      if (speakerTimeoutRef.current) clearTimeout(speakerTimeoutRef.current);
      speakerTimeoutRef.current = setTimeout(() => {
        generateSentimentScore();
        lastSpeakerRef.current = "";
        currentSentenceRef.current = "";
        currentStartTimeRef.current = 0;
        currentEndTimeRef.current = 0;
      }, 10 * 1000);

      if (currentSpeaker !== speaker) {
        // Add speaker information only when it changes
        currArr.push({
          speaker,
          content: entry.sentence,
          start: entry.start - pausedOffset.current,
          end: entry.end - pausedOffset.current,
          type: "speaker-change",
          chat_time: Math.floor(Date.now() / 1000),
        });
        generateSentimentScore();
        currentSpeaker = speaker;
        lastSpeakerRef.current = speaker;
        currentSentenceRef.current = `\n${speaker}:`;
        currentStartTimeRef.current = entry.start;
      } else {
        currArr.push({
          content: entry.sentence,
          end: entry.end - pausedOffset.current,
          type: "same-speaker",
          chat_time: Math.floor(Date.now() / 1000),
        });
      }

      currentEndTimeRef.current = entry.end;
      currentSentenceRef.current += " " + entry.sentence;
    });
    console.log(currArr);
    dispatch(setTranscriptArr(currArr));
  };

  const convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);
    if (pausedRef.current) raw.fill(0);

    if (raw == null) return;
    // console.log("inputSampleRateRef.current", inputSampleRateRef.current)
    let downsampledBuffer = downsampleBuffer(
      raw,
      inputSampleRateRef.current,
      sampleRate
    );
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    return pcmEncodedBuffer;
  };

  const onSessionStartClick = () => {
    setSessionStartButton(!sessionStartButton);
  };

  const onRecordingStop = () => {
    stopRecording();
    recorder?.stopRecording();
    onSessionStopClick();
    socketRef.current.close();
    socketRef.current = null;
    stopWatch.reset();
  };

  const onRecordingPause = () => {
    // microphoneStreamRef.current.pauseRecording();
    setRecordingPaused(true);
    pausedRef.current = true;
    recorder.stopRecording();
    stopWatch.start();
  };

  const onRecordingResume = () => {
    // microphoneStreamRef.current.playRecording();
    setRecordingPaused(false);
    pausedOffset.current = stopWatch.totalSeconds;
    pausedRef.current = false;
    recorder.startRecording();
    stopWatch.pause();
    dispatch(setMicrophoneStatus(true));
    // clearInterval(pauseIntervalRef.current);
  };

  return (
    <>
      {!sessionStartButton ? (
        <RealtimePanelInactiveComponent startRecording={startRecording} />
      ) : (
        <RealtimePanelActiveComponent
          highlights={highlights}
          clickedChip={clickedChip}
          newHighlights={newHighlights}
          handleChipClick={handleChipClick}
          recorder={recorder}
          recordingPaused={recordingPaused}
          onRecordingStop={onRecordingStop}
          onViewTranscriptClick={onViewTranscriptClick}
          onSessionStartClick={onSessionStartClick}
          onRecordingPause={onRecordingPause}
          onRecordingResume={onRecordingResume}
          transcriptViewPanel={transcriptViewPanel}
        />
      )}
    </>
  );
};

export default RealtimePanel;
