import React from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend,
} from "recharts";

const ClientMetrics = () => {
  // Get data from Redux store
  const {
    risk_summary,
    sessions_weekwise,
    therapy_status,
    sessions_per_day,
    loading,
  } = useSelector((state) => state.clientDashboard);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full">
        <p className="text-lg font-bold">Loading Metrics...</p>
      </div>
    );
  }

  // Risk Distribution Data
  const riskData = [
    { name: "High Risk", value: risk_summary.HIGH, color: "#EF4444" },
    { name: "Medium Risk", value: risk_summary.MEDIUM, color: "#F59E0B" },
    { name: "Low Risk", value: risk_summary.LOW, color: "#10B981" },
  ];

  // Session Completion Data (Last 4 Weeks)
  const sessionData = sessions_weekwise.map((week) => ({
    week: `Week ${week.week_number}`,
    completed: week.completed_sessions,
    scheduled: week.scheduled_sessions,
  }));

  // Client Status Data
  const statusData = [
    { status: "Active", count: therapy_status.ACTIVE },
    { status: "On Hold", count: therapy_status.ON_HOLD },
    { status: "Invited", count: therapy_status.INVITED },
    { status: "Completed", count: therapy_status.TERMINATED },
  ];

  // Weekly Trends Data
  const trendsData = Object.keys(sessions_per_day).map((day) => ({
    day,
    sessions: sessions_per_day[day],
  }));

  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      {/* Risk Distribution Chart */}
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Client Risk Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={riskData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={2}
                  dataKey="value"
                >
                  {riskData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Session Completion Rate */}
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Session Completion Rate</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sessionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="completed" fill="#3B82F6" name="Completed" />
                <Bar dataKey="scheduled" fill="#93C5FD" name="Scheduled" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Client Status Overview */}
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Client Status Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={statusData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="status" type="category" />
                <Tooltip />
                <Bar dataKey="count" fill="#60A5FA" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Weekly Session Trends */}
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Weekly Session Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={trendsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="sessions"
                  stroke="#3B82F6"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientMetrics;
