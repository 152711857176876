export const toolTipText = {
  overview: {
    priority: "This is a tooltip",
    risk: "This is risk",
    activeCases: "Number of active patients taking therapy",
    clientProgress: "Progress made by client",
    sessionScore: "Overall session score",
    teamMembersNumber: "Number of therapists including you under your guidance",
    upcomingSessions: "List of upcoming sessions of you and your team members",
    teamMembersDetails: "Detailed view of your team members",
  },
  client: {
    riskDistribution: "Risk distribution of your patients",
    sessionCompletionRate:
      "Comparison between scheduled and completed sessions over last 4 weeks",
    clientStatusOverview: "Distribution of patient status",
    weeklySessionTrends: "Number of completed sessions in a week",
  },
};
