import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LayoutDashboard, Users, ChartColumnBig } from "lucide-react";
import ASSETS, { sidebar_bg } from "../../assets/Assets";
import "./Sidebar.css";

export default function Sidebar({ userRole }) {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-menu">
        <img
          src={sidebar_bg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: userRole === "SUPERVISOR" ? "130%" : "110%",
            height: "100%",
          }}
          alt="sidebar background"
        />
        <TherapistSideBar userRole={userRole} />
      </div>
    </div>
  );
}

const TherapistSideBar = ({ userRole }) => {
  const location = useLocation();

  return (
    <div
      className={
        userRole === "SUPERVISOR" ? "z-index-high ml-2" : "z-index-high"
      }
    >
      {userRole === "SUPERVISOR" && (
        <Link to="/overview" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/overview"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            {location.pathname !== "/overview" ? (
              <img
                className="sidebar-icon"
                src={ASSETS.dashboardWhite}
                alt="dashboard white"
              />
            ) : (
              <img
                className="sidebar-icon"
                src={ASSETS.dashboardBlue}
                alt="dashboard blue"
              />
            )}
          </div>
        </Link>
      )}
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/dashboard"
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {location.pathname !== "/dashboard" ? (
            userRole === "SUPERVISOR" ? (
              <LayoutDashboard size={24} color="#FFFFFF" />
            ) : (
              <img
                className="sidebar-icon"
                src={ASSETS.dashboardWhite}
                alt="dashboard white"
              />
            )
          ) : userRole === "SUPERVISOR" ? (
            <LayoutDashboard size={24} color="#5f6ce1" />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.dashboardBlue}
              alt="dashboard blue"
            />
          )}
        </div>
      </Link>
      <Link to="/clients" style={{ textDecoration: "none" }}>
        <div
          className={
            !location.pathname.includes("/clients")
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {!location.pathname.includes("/clients") ? (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionWhite}
              alt="description white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionBlue}
              alt="description blue"
            />
          )}
        </div>
      </Link>
      {userRole === "SUPERVISOR" && (
        <Link to="/team" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/team"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            {location.pathname !== "/team" ? (
              <Users size={24} color="#FFFFFF" />
            ) : (
              <Users size={24} color="#5f6ce1" />
            )}
          </div>
        </Link>
      )}
      <Link to="/analysis" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/analysis"
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {location.pathname !== "/analysis" ? (
            <ChartColumnBig size={24} color="#FFFFFF" />
          ) : (
            <ChartColumnBig size={24} color="#5f6ce1" />
          )}
        </div>
      </Link>
      <Link to="/settings?tab=profile" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/settings"
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {location.pathname !== "/settings" ? (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsWhite}
              alt="settings white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsBlue}
              alt="settings blue"
            />
          )}
        </div>
      </Link>
    </div>
  );
};
