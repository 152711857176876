import React, { useEffect, useState, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  TrendingUp,
  Heart,
  MessageCircle,
  Share2,
  Target,
  CheckCircle2,
  ArrowUp,
  Brain,
  Handshake,
  Lightbulb,
  Sparkles,
  Award,
  Loader2,
  AlertCircle
} from 'lucide-react';
import { getRealtimeSessionInfoById } from '../../../../../api/realtime';

const DashboardError = ({
  title = "Unable to load dashboard data",
  message = "There was a problem loading the dashboard. Please try again or contact support if the problem persists.",
}) => {
  return (
    <div className="min-h-screen bg-[#F8F9FE] p-6 flex items-center justify-center">
      <div className="bg-white rounded-lg border border-gray-200 p-8 shadow-sm max-w-md w-full">
        <div className="flex flex-col items-center text-center">
          {/* Error Icon */}
          <div className="w-16 h-16 bg-red-50 rounded-full flex items-center justify-center mb-4">
            <AlertCircle className="h-8 w-8 text-red-500" />
          </div>

          {/* Error Title */}
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            {title}
          </h2>

          {/* Error Message */}
          <p className="text-sm text-gray-600 mb-6">
            {message}
          </p>

        </div>
      </div>
    </div>
  );
};

const TherapistReportV2 = ({ therapistId, realtimeSessionId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [compatibilityDetails, setCompatibilityDetails] = useState(null);

  const [timelineData, setTimelineData] = useState([]);
  const [overallScores, setOverallScores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const MIN_SENTENCE_LENGTH = 50;

      try {
        const response = await getRealtimeSessionInfoById(therapistId, realtimeSessionId);
        const parsedReport = response.data.report ? JSON.parse(response.data.report) : null;
        // console.log("parsedReport", parsedReport)

        // Sum the scores from sub_scores
        const subScores = parsedReport?.compatibility_score?.sub_scores;
        const totalSubScore = subScores
          ? Object.values(subScores).reduce((acc, item) => acc + (item.score || 0), 0)
          : 0;

        // compatibilityDetailsの生成
        const details = {
          score: totalSubScore,
          reason: parsedReport.compatibility_score?.overall_score?.reasons || "No reasons provided.",
          improvements: (parsedReport.compatibility_score?.areas_of_improvement || []).map(
            (improvement) => improvement.suggestion || "No suggestion provided."
          ),
          statusHeading: parsedReport.compatibility_score?.overall_score?.score >= 80 ? "Strong Alignment" : "Needs Improvement",
          statusDescription: parsedReport.compatibility_score?.overall_score?.score >= 80
            ? "Excellent therapeutic alliance"
            : "Therapeutic alliance needs strengthening",
          badgeText: parsedReport.compatibility_score?.overall_score?.score >= 80 ? "Above Target" : "Below Target",
          badgeColor: parsedReport.compatibility_score?.overall_score?.score >= 80 ? "text-violet-600 bg-violet-50" : "text-red-600 bg-red-50",
          color: "#8B5CF6",
        };

        console.log("details", details)

        setCompatibilityDetails(details);

        const generateTimelineData = (scores) => {
          if (!scores || scores.length === 0) return [];

          return scores
            .filter((score) => score.sentence && score.sentence.length >= MIN_SENTENCE_LENGTH)
            .map((score, index) => ({
              time: index * 3,
              intervention: score.scores?.intervention_score?.score || 0,
              empathy: score.scores?.empathy_score?.score || 0,
              engagement: score.scores?.engagement_score?.score || 0,
              language_and_communication: score.scores?.language_and_communication_score?.score || 0,
              sentence: score.sentence || "No sentence available",
            }));
        };

        const timelineData = generateTimelineData(parsedReport.scores);
        setTimelineData(timelineData);

        const calculateAverage = (data, key) => {
          if (!data || data.length === 0) return 0;
          const sum = data.reduce((acc, item) => acc + (item[key] || 0), 0);
          return (sum / data.length).toFixed(1); // 小数点1桁でフォーマット
        };

        const generateOverallScores = (timelineData, parsedReport) => {
          const scoreKeys = [
            { id: 'intervention', name: 'Intervention', icon: <Brain className="h-4 w-4" />, color: '#6366F1' },
            { id: 'empathy', name: 'Empathy', icon: <Heart className="h-4 w-4" />, color: '#8B5CF6' },
            { id: 'language_and_communication', name: 'Communication', icon: <MessageCircle className="h-4 w-4" />, color: '#EC4899' },
            { id: 'engagement', name: 'Engagement', icon: <Share2 className="h-4 w-4" />, color: '#F59E0B' },
          ];

          return scoreKeys.map((key) => {
            const averageScore = calculateAverage(timelineData, key.id);

            // 最も低いスコアのセンテンスを見つける
            const lowestScoreEntry = parsedReport.scores
              .filter((score) => score.sentence && score.sentence.length >= MIN_SENTENCE_LENGTH)
              .map((score) => ({
                reason: score.scores?.[`${key.id}_score`]?.reason || `No specific reason provided for ${key.name}.`,
                improvements: score.scores?.[`${key.id}_score`]?.areas_of_improvement || [`Improve ${key.name} further.`],
                score: score.scores?.[`${key.id}_score`]?.score || 0,
              }))
              .sort((a, b) => a.score - b.score)[0];

            const reason = lowestScoreEntry?.reason || `No specific reason provided for ${key.name}.`;
            const improvements = lowestScoreEntry?.improvements || [`Improve ${key.name} further.`];

            const status =
              averageScore >= 80
                ? 'Outstanding'
                : averageScore >= 70
                  ? 'Good progress'
                  : 'Needs attention';

            return {
              id: key.id,
              name: key.name,
              score: parseFloat(averageScore),
              icon: key.icon,
              color: key.color,
              reason,
              improvements,
              status,
            };
          });
        };

        const overallScores = generateOverallScores(timelineData, parsedReport);
        setOverallScores(overallScores);


        //   const formattedData = formatTherapistReportData(response.data);
        //   setReportData(formattedData);

        //   const metrics = calculateScoreMetrics(formattedData.scores);
        //   setAverageScores(calculateAverageScores(metrics));

        //   const timeline = mapScoresToTimeline(formattedData.scores);
        //   setTimelineData(timeline);
      } catch (error) {
        console.error('Error fetching report data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // const compatibilityDetails = {
  //   score: 85,
  //   reason: "Strong therapeutic alliance demonstrated through consistent rapport and mutual understanding",
  //   improvements: [
  //     "Deepen exploration of client's personal goals",
  //     "Enhance alignment on treatment priorities",
  //     "Strengthen collaborative decision-making"
  //   ],
  //   status: "Excellent"
  // };

  // const compatibilityDetails = {
  //   score: parsedReport.compatibility_score.score,
  //   reason: parsedReport.compatibility_score.reasons,
  //   improvements: [
  //     parsedReport.compatibility_score.areas_of_improvement.suggestions,
  //     ...parsedReport.compatibility_score.areas_of_improvement.transcript_examples.map(
  //       (example) => `Consider: "${example.therapist}" for deeper exploration.`
  //     ),
  //   ],
  //   status: parsedReport.compatibility_score.score >= 85 ? "Excellent" : "Needs Improvement",
  // };

  // const timelineData = Array.from({ length: 20 }, (_, i) => ({
  //   time: i * 3,
  //   intervention: 75 + Math.random() * 20 - 10,
  //   empathy: 80 + Math.random() * 15 - 7.5,
  //   engagement: 70 + Math.random() * 25 - 12.5,
  //   communication: 85 + Math.random() * 10 - 5
  // }));

  // const overallScores = [
  //   {
  //     id: 'intervention',
  //     name: 'Intervention',
  //     score: 75.5,
  //     icon: <Brain className="h-4 w-4" />,
  //     color: '#6366F1',
  //     reason: 'Strong timing and appropriateness of interventions',
  //     improvements: [
  //       'Consider more varied intervention types',
  //       'Increase follow-up questions',
  //       'Enhance timing of challenging interventions'
  //     ],
  //     status: 'Good progress'
  //   },
  //   {
  //     id: 'empathy',
  //     name: 'Empathy',
  //     score: 82.3,
  //     icon: <Heart className="h-4 w-4" />,
  //     color: '#8B5CF6',
  //     reason: 'Excellent emotional attunement and validation',
  //     improvements: [
  //       'Deepen reflection of complex emotions',
  //       'Expand range of empathic responses',
  //       'Include more nonverbal empathy cues'
  //     ],
  //     status: 'Excellent'
  //   },
  //   {
  //     id: 'communication',
  //     name: 'Communication',
  //     score: 88.7,
  //     icon: <MessageCircle className="h-4 w-4" />,
  //     color: '#EC4899',
  //     reason: 'Clear and adaptive communication style',
  //     improvements: [
  //       'Adjust language complexity when needed',
  //       'Include more metaphors and examples',
  //       'Enhance clarity in complex explanations'
  //     ],
  //     status: 'Outstanding'
  //   },
  //   {
  //     id: 'engagement',
  //     name: 'Engagement',
  //     score: 79.2,
  //     icon: <Share2 className="h-4 w-4" />,
  //     color: '#F59E0B',
  //     reason: 'Good client participation and involvement',
  //     improvements: [
  //       'Increase interactive exercises',
  //       'Add more open-ended exploration',
  //       'Enhance engagement during difficult topics'
  //     ],
  //     status: 'Needs attention'
  //   }
  // ];

  const ScoreDetail = ({ details, compact = false }) => (
    <div className={`${compact ? 'mt-4' : 'mt-6'} space-y-4`}>
      <div className="p-3 bg-white rounded-xl border border-gray-100 shadow-sm">
        <h4 className="font-medium text-gray-900 mb-2 flex items-center gap-2">
          <Lightbulb className="h-4 w-4 text-amber-500" />
          Why this score?
        </h4>
        <p className="text-sm text-gray-600 pl-6">{details.reason}</p>
      </div>
      <div className="p-3 bg-white rounded-xl border border-gray-100 shadow-sm">
        <h4 className="font-medium text-gray-900 mb-2 flex items-center gap-2">
          <Target className="h-4 w-4 text-blue-500" />
          How to improve?
        </h4>
        <ul className="list-none text-sm text-gray-600 space-y-1 pl-6">
          {details.improvements.map((imp, i) => (
            <li key={i} className="flex items-start gap-2">
              <ArrowUp className="h-3 w-3 mt-1 flex-shrink-0" style={{ color: details.color }} />
              <span>{imp}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const minValues = useMemo(() => {
    return {
      intervention: Math.min(...timelineData.map(d => d.intervention)),
      empathy: Math.min(...timelineData.map(d => d.empathy)),
      language_and_communication: Math.min(...timelineData.map(d => d.language_and_communication)),
      engagement: Math.min(...timelineData.map(d => d.engagement))
    };
  }, [timelineData]);

  const CustomDot = (props) => {
    const { cx, cy, value, dataKey, stroke } = props;
    const isMin = value === minValues[dataKey];

    if (!isMin) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r={3}
          fill={stroke}
          opacity={0.6}
        />
      );
    }

    return (
      <g>
        {/* 外側のパルスアニメーション */}
        {/* <circle
          cx={cx}
          cy={cy}
          r={12}
          fill="none"
          stroke={stroke}
          strokeWidth={1}
          opacity={0.3}
          className="animate-ping"
        /> */}

        {/* 中間サイズの円 */}
        <circle
          cx={cx}
          cy={cy}
          r={10}
          fill="none"
          stroke={stroke}
          strokeWidth={1.5}
          opacity={0.5}
        />

        {/* メインの点 */}
        <circle
          cx={cx}
          cy={cy}
          r={6}
          fill={stroke}
          stroke="white"
          strokeWidth={2}
        // className="animate-pulse"
        />

        {/* 最小値のラベル */}
        <g transform={`translate(${cx}, ${cy - 20})`}>
          <rect
            x={-24}
            y={-12}
            width={48}
            height={16}
            rx={8}
            fill={stroke}
            opacity={0.1}
          />
          <text
            textAnchor="middle"
            dy="-2"
            fill={stroke}
            fontSize="10"
            fontWeight="600"
          >
            Lowest
          </text>
        </g>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0].value;
      const isMinPoint = value === minValues[payload[0].dataKey];
      const sentence = payload[0]?.payload?.sentence || "No sentence available"; // データから sentence を取得

      return (
        <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
          <p className="text-sm text-gray-600">Time: {label}</p>
          <p className="text-sm font-medium text-gray-800">Sentence:</p>
          <p
            className="text-sm text-gray-600 italic break-words"
            style={{
              wordWrap: "break-word",
              maxWidth: "150px",
              whiteSpace: "pre-wrap",
            }}
          >
            "{sentence}"
          </p>
          <div className="mt-1 flex items-center gap-2">
            <p className="text-sm font-medium" style={{ color: payload[0].color }}>
              Score: {value.toFixed(1)}%
            </p>
            {isMinPoint && (
              <span
                className="px-1.5 py-0.5 rounded-full text-xs font-medium"
                style={{
                  backgroundColor: `${payload[0].color}15`,
                  color: payload[0].color
                }}
              >
                Lowest Point
              </span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  if (error) {
    return (
      <DashboardError
        title="Unable to load dashboard data"
        message={error.message}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#F8F9FE]">
        <Loader2 className="h-12 w-12 text-violet-600 animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F8F9FE] p-6">
      <div className="w-full space-y-6">
        {/* Compatibility Score Card */}
        {compatibilityDetails ? (
          <div className="bg-white rounded-lg border border-gray-200 relative overflow-hidden shadow-sm">
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-b from-violet-100 to-transparent rounded-full -mr-16 -mt-16 opacity-50" />
            <div className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-t from-violet-100 to-transparent rounded-full -ml-12 -mb-12 opacity-50" />

            <div className="p-6 pb-3 relative">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-violet-50 rounded-lg shadow-sm">
                  <Handshake className="h-5 w-5 text-violet-600" />
                </div>
                <h2 className="text-base font-medium text-gray-900">
                  Compatibility Score
                </h2>
              </div>
            </div>

            <div className="p-6 pt-0 relative">

              <div className="flex items-center gap-8">
                <div className="relative w-48 h-48 flex-shrink-0">
                  <div className="absolute inset-0 rounded-full bg-violet-50 animate-pulse" />
                  <div className="absolute inset-[-8px] rounded-full border-4 border-violet-100 opacity-50" />
                  <div className="absolute inset-2 rounded-full bg-gradient-to-br from-violet-500 to-purple-600 flex items-center justify-center text-white shadow-lg">
                    <div className="flex flex-col items-center justify-center h-full">
                      <span className="text-6xl font-bold mb-2">{compatibilityDetails.score}%</span>
                      {/* <Sparkles className="h-6 w-6" /> */}
                      <div className="flex items-center gap-1 text-sm text-white/80">
                        <Target className="h-4 w-4" />
                        <span>80%</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <h3 className="font-medium text-gray-900 text-lg">{compatibilityDetails.statusHeading}</h3>
                  <p className="text-sm text-gray-500 mt-1">{compatibilityDetails.statusDescription}</p>
                  <div className={`mt-2 inline-flex items-center gap-2 text-sm px-3 py-1.5 rounded-full shadow-sm ${compatibilityDetails.badgeColor}`}>
                    <Award className="h-4 w-4" />
                    <span>{compatibilityDetails.badgeText}</span>
                  </div>

                  <ScoreDetail
                    details={{ ...compatibilityDetails, color: '#8B5CF6' }}
                    compact={true}
                  />
                </div>
              </div>

            </div>
          </div>
        ) : (
          <></>
        )}

        {/* Overall Performance Card */}
        <div className="bg-white rounded-lg border border-gray-200 relative overflow-hidden shadow-sm">
          <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-b from-blue-50 to-transparent rounded-full -ml-16 -mt-16 opacity-50" />
          <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-t from-blue-50 to-transparent rounded-full -mr-12 -mb-12 opacity-50" />

          <div className="p-6 pb-3 relative">
            <div className="flex justify-between items-start">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-blue-50 rounded-lg shadow-sm">
                  <TrendingUp className="h-5 w-5 text-blue-600" />
                </div>
                <h2 className="text-base font-medium text-gray-900">
                  Overall Performance
                </h2>
              </div>
            </div>
          </div>

          <div className="p-6 pt-0">
            <div className="space-y-4">
              {overallScores.map((score) => (
                <div key={score.id} className="group">
                  <div className="flex items-center justify-between mb-1">
                    <div className="flex items-center gap-2">
                      <div className="p-1.5 rounded-lg transition-colors"
                        style={{ backgroundColor: `${score.color}15` }}>
                        {React.cloneElement(score.icon, {
                          className: 'h-4 w-4 transition-transform group-hover:scale-110',
                          style: { color: score.color }
                        })}
                      </div>
                      <span className="text-sm text-gray-600">{score.name}</span>
                    </div>
                    <span className="font-medium text-sm"
                      style={{ color: score.color }}>
                      {score.score}%
                    </span>
                  </div>
                  <div className="w-full h-1.5 rounded-full bg-gray-100">
                    <div
                      className="h-full rounded-full transition-all"
                      style={{
                        width: `${score.score}%`,
                        backgroundColor: score.color
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4 pt-4 border-t border-gray-100">
              <div className="flex items-center gap-2 text-xs text-gray-500">
                <Target className="h-3 w-3" />
                <span>Target Score: 90%</span>
              </div>
            </div>
          </div>
        </div>

        {/* Individual Metric Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {overallScores.map((score) => (
            <div key={score.id} className="bg-white rounded-lg border border-gray-200 relative overflow-hidden shadow-sm">
              <div
                className="absolute top-0 right-0 w-32 h-32 rounded-full -mr-16 -mt-16 opacity-10"
                style={{ backgroundColor: score.color }}
              />
              <div
                className="absolute bottom-0 left-0 w-24 h-24 rounded-full -ml-12 -mb-12 opacity-10"
                style={{ backgroundColor: score.color }}
              />

              <div className="p-6 pb-3 relative">
                <div className="flex justify-between items-start">
                  <div className="flex items-center gap-2">
                    <div
                      className="p-2 rounded-lg shadow-sm transition-colors"
                      style={{ backgroundColor: `${score.color}15` }}
                    >
                      {React.cloneElement(score.icon, {
                        className: 'h-5 w-5',
                        style: { color: score.color }
                      })}
                    </div>
                    <h2 className="text-base font-medium text-gray-900">
                      {score.name}
                    </h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <div
                      className="flex items-center gap-1 px-2 py-1 rounded-full text-xs shadow-sm"
                      style={{ backgroundColor: `${score.color}15`, color: score.color }}
                    >
                      <CheckCircle2 className="h-4 w-4" />
                      <span>{score.status}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-6 pt-0 relative">
                <div className="mb-4 flex justify-between items-center">
                  <div className="flex items-baseline gap-2">
                    <span className="text-2xl font-bold" style={{ color: score.color }}>
                      {score.score}%
                    </span>
                    <div className="flex items-center gap-1 text-sm text-gray-500">
                      <Target className="h-4 w-4" />
                      <span>70%</span>
                    </div>
                  </div>
                </div>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart
                    data={timelineData}
                    margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                  >
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey={score.id.toLowerCase()}
                      stroke={score.color}
                      strokeWidth={2}
                      dot={(props) => <CustomDot {...props} />}
                      activeDot={{
                        r: 8,
                        strokeWidth: 2,
                        stroke: 'white',
                        className: 'hover:animate-pulse'
                      }}
                    />
                  </LineChart>
                </ResponsiveContainer>

                <ScoreDetail details={score} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TherapistReportV2;
