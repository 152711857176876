import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import { Alert, AlertDescription } from "../../components/alert";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../utils/helpers";
import {
  Bell,
  AlertTriangle,
  Users,
  Activity,
  TrendingUp,
  UserCircle,
  ArrowUpRight,
} from "lucide-react";
import { toolTipText } from "../../utils/toolTipText";

const Overview = () => {
  const {
    supervisor_id,
    loading,
    team_members = [],
    upcoming_meetings = [],
    patient_statistics = {},
    meeting_insights = {},
  } = useSelector((state) => state.overviewDashboard);

  const [sessionView, setSessionView] = useState("personal");

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full h-full flex flex-col gap-4 ml-16 p-8 overflow-y-scroll">
      <div className="heading">Overview</div>
      <div className="info-text dashboard-info-text">
        You can check the overview data here
      </div>

      {/* Priority Insights Section */}
      <div className="grid grid-cols-3 gap-6 mb-6">
        <Card
          className="col-span-2"
          toolTipText={toolTipText.overview.priority}
        >
          <CardHeader>
            <CardTitle className="text-lg font-semibold flex items-center gap-2">
              <Bell className="h-5 w-5 text-blue-500" />
              Today's Priority Insights
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {meeting_insights.latest_insights.slice(0, 2).map((data, idx) => {
                console.log(idx);
                return (
                  <Alert
                    key={data.timestamp}
                    className={`border-${idx === 0 ? "red" : "amber"}-100 bg-${
                      idx === 0 ? "red" : "amber"
                    }-50`}
                  >
                    <AlertTriangle className="h-4 w-4 text-red-500" />
                    <AlertDescription className="text-sm text-slate-700">
                      {data.insight}
                    </AlertDescription>
                  </Alert>
                );
              })}
            </div>
          </CardContent>
        </Card>

        <Card toolTipText={toolTipText.overview.risk}>
          <CardHeader>
            <CardTitle className="text-lg font-semibold flex items-center gap-2">
              <AlertTriangle className="h-5 w-5 text-red-500" />
              Risk Alerts
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              {[
                {
                  label: "High Risk",
                  count: patient_statistics.high_risk_patients,
                  color: "red",
                },
                {
                  label: "Medium Risk",
                  count: patient_statistics.medium_risk_patients,
                  color: "amber",
                },
                // {
                //   label: "Low Risk",
                //   count: patient_statistics.low_risk_patients,
                //   color: "green",
                // },
              ].map((risk, index) => (
                <div
                  key={index}
                  className={`p-3 bg-${risk.color}-50 rounded-lg border border-${risk.color}-100`}
                >
                  <div className="flex items-center justify-between">
                    <span
                      className={`text-sm font-medium text-${risk.color}-800`}
                    >
                      {risk.label}
                    </span>
                    <span className={`text-xs text-${risk.color}-600`}>
                      {risk.count} clients
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Team Performance Section */}
      <div className="grid grid-cols-4 gap-6 mb-6">
        <Card toolTipText={toolTipText.overview.activeCases}>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-slate-600">
                  Active Cases
                </p>
                <h3 className="text-2xl font-bold text-slate-800">
                  {team_members.length}
                </h3>
              </div>
              <Users className="h-8 w-8 text-blue-500" />
            </div>
            <div className="mt-2">
              <span className="text-xs text-green-600 flex items-center">
                <ArrowUpRight className="h-3 w-3" /> +5% from last month
              </span>
            </div>
          </CardContent>
        </Card>

        <Card toolTipText={toolTipText.overview.clientProgress}>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-slate-600">
                  Client Progress
                </p>
                <h3 className="text-2xl font-bold text-slate-800">
                  {Math.round(meeting_insights.average_client_progress_score)}%
                </h3>
              </div>
              <Activity className="h-8 w-8 text-blue-500" />
            </div>
            <div className="mt-2">
              <span className="text-xs text-green-600 flex items-center">
                <ArrowUpRight className="h-3 w-3" /> +2% improvement
              </span>
            </div>
          </CardContent>
        </Card>

        <Card toolTipText={toolTipText.overview.sessionScore}>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-slate-600">
                  Session Score
                </p>
                <h3 className="text-2xl font-bold text-slate-800">
                  {Math.round(meeting_insights.average_session_score)}
                </h3>
              </div>
              <TrendingUp className="h-8 w-8 text-blue-500" />
            </div>
            <div className="mt-2">
              <span className="text-xs text-green-600 flex items-center">
                <ArrowUpRight className="h-3 w-3" /> +0.3 points
              </span>
            </div>
          </CardContent>
        </Card>

        <Card toolTipText={toolTipText.overview.teamMembersNumber}>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-slate-600">
                  Team Members
                </p>
                <h3 className="text-2xl font-bold text-slate-800">
                  {team_members.length}
                </h3>
              </div>
              <Users className="h-8 w-8 text-blue-500" />
            </div>
            <div className="mt-2">
              <span className="text-xs text-blue-600">All active</span>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Sessions and Team Members Grid */}
      <div className="grid grid-cols-5 gap-6">
        {/* Upcoming Sessions - 60% width */}
        <Card
          className="col-span-3"
          toolTipText={toolTipText.overview.upcomingSessions}
        >
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="text-lg font-semibold">
              Upcoming Sessions
            </CardTitle>
            <select
              value={sessionView}
              onChange={(e) => setSessionView(e.target.value)}
              className="text-sm border rounded-md px-2 py-1 text-slate-600"
            >
              <option value="personal">My Clients</option>
              <option value="team">Team Clients</option>
            </select>
          </CardHeader>

          <CardContent className="h-96 overflow-y-auto pr-2">
            <div className="space-y-3">
              {upcoming_meetings
                .filter((meeting) => {
                  return sessionView === "personal"
                    ? meeting.therapist_id === supervisor_id
                    : meeting.therapist_id !== supervisor_id;
                })
                .map((session, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 bg-white border border-solid rounded-lg hover:bg-slate-50"
                    >
                      <div>
                        <h4 className="font-medium text-slate-800">
                          {session.therapy_name}
                        </h4>
                        <p className="text-sm text-slate-600">
                          {format(
                            parseISO(
                              mysqlDatetimeToJavascriptDatetime(
                                session.start_datetime
                              )
                            ),
                            "do MMM y"
                          )}
                        </p>
                      </div>
                      <span
                        className={`text-sm px-2 py-1 rounded ${
                          session.meeting_status === ""
                            ? "bg-green-50 text-green-700"
                            : session.meeting_status === "CONFIRMED"
                            ? "bg-green-50 text-green-700"
                            : "bg-amber-50 text-amber-700"
                        }`}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {session.meeting_status === ""
                          ? "Confirmed"
                          : session.meeting_status.toLowerCase()}
                      </span>
                    </div>
                  );
                })}
            </div>
          </CardContent>
        </Card>

        {/* Team Members List - 40% width */}
        <Card
          className="col-span-2"
          toolTipText={toolTipText.overview.teamMembersDetails}
        >
          <CardHeader>
            <CardTitle className="text-lg font-semibold">
              Team Members
            </CardTitle>
          </CardHeader>
          <CardContent className="h-96 overflow-y-auto pr-2">
            <div className="divide-y">
              {team_members.map((member, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between py-4 first:pt-0 hover:bg-slate-50 px-2 rounded"
                  >
                    <div className="flex items-center space-x-4">
                      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                        <UserCircle className="w-8 h-8 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="font-medium text-slate-800">
                          {member.therapist_name}
                        </h3>
                        <p className="text-sm text-slate-600">
                          {member.completed_sessions}/
                          {member.total_scheduled_sessions} sessions overall
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="text-sm font-medium text-green-600">
                        {Math.round(
                          (member.completed_sessions /
                            member.total_scheduled_sessions) *
                            100
                        )}
                        %
                      </div>
                      <div className="text-xs text-slate-600">completion</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Overview;
