import "./clientChart.css";
import ClientDetailsContainer from "./clientDetailsContainer";
import ClientUtilitiesTab from "./clientUtilitiesTab";
import React, { useState, useEffect, useRef } from "react";
import ASSETS from "../../../../assets/Assets";
import ProgressReportLoader from "./Assets/ProgressReportLoader.gif";

import RealtimePanelAWS from "./RealtimePanel/RealtimePanelAWS";
import RealtimePanel from "./RealtimePanel/RealtimePanel";
import TranscriptPanel from "./RealtimePanel/TranscriptPanel";
import RealtimeSessionReport from "./RealtimePanel/RealtimeSessionReport/RealtimeSessionReport";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import {
  createAiReportWithTranscript,
  createRealtimeSession,
  determineRoleFromTranscript,
  saveSentimentScoresAPI,
  saveRealtimeTranscript,
  updateDurationOfRealtimeSession,
  saveRealtimeAudioAPI,
  getRealtimeAudioUploadURLAPI,
} from "../../../../api/realtime";
import {
  clearRealtimeSessionstore,
  clearTranscript,
  removeClientSentimentScore,
  setSpeakerLabel,
  setTranscriptArr,
  setTranscriptLanguage,
} from "../../../../store/liveSession/liveSessionSlice";
import { ThreeDots } from "react-loader-spinner";
import ProgressBar from "./RealtimePanel/RealtimeSessionReport/ProgressBar";
import {
  setLiveSessionId,
  setNoteId,
} from "../../../../store/liveSession/liveSessionSlice";
import { generate_report } from "../../../../api/notes";
import {
  _parseConversationString,
  convertTranscriptArrForSaving,
  generateTranscriptTextFromArray,
  parseConversationString,
  replaceMultipleStrings,
} from "./RealtimePanel/TranscriptPanelUtls";

import { formatDateToYYYYMMDDHHMMSS } from "../../../../utils/helpers";
import { stripTimestampFromTranscript } from "./RealtimePanel/TranscriptPanelUtls";
import { uploadFiles } from "../../../../api/s3";

export const processAiReport = (unformattedSoapData, reportType) => {
  let dataLabels;
  const soapDataLabels = [
    "Subjective",
    "Objective",
    "Assessment",
    "Plan",
    "Summary",
  ];
  const dapDataLabels = ["Data", "Assessment", "Plan", "Summary"];
  const birpDataLabels = [
    "Behavior",
    "Intensity",
    "Response",
    "Plan",
    "Summary",
  ];
  const girpDataLabels = [
    "Goals",
    "Interventions",
    "Response",
    "Plan",
    "Summary",
  ];

  switch (reportType) {
    case "SOAP":
      dataLabels = soapDataLabels;
      break;
    case "DAP":
      dataLabels = dapDataLabels;
      break;
    case "BIRP":
      dataLabels = birpDataLabels;
      break;
    case "GIRP":
      dataLabels = girpDataLabels;
      break;
    default:
      return;
  }

  const progressNotesFormatted = {};
  dataLabels.map((label) => {
    progressNotesFormatted[label.toLowerCase()] =
      unformattedSoapData.progress_notes.Notes.find(
        (note) => note.heading === label
      )?.text;
  });

  const suggestionsFormatted = {};
  dataLabels.map((label) => {
    if (label.toLowerCase() !== "summary") {
      suggestionsFormatted[label.toLowerCase()] =
        unformattedSoapData.suggestions.Notes.filter(
          (note) => note.heading.indexOf(`${label}`) !== -1
        ).map((note) => ({
          topic_name: note.topic_name,
          text: note.text,
        }));
    }
  });

  return { progressNotesFormatted, suggestionsFormatted };
};

const ClientChart = function () {
  const dispatch = useDispatch();

  const [realtimeButton, setRealtimeButton] = useState(false);
  const [showReportLoading, setShowReportLoading] = useState(false);
  const [transcriptViewPanel, setTranscriptViewPanel] = useState(false);
  const [realtimeReportModal, setRealtimeReportModal] = useState(false);

  const [reportProgress, setReportProgress] = useState(0);

  const [soapData, setSoapData] = useState({});
  const [soapSuggestions, setSoapSuggestions] = useState({});

  const [dapData, setDapData] = useState({});
  const [dapSuggestions, setDapSuggestions] = useState({});

  const [birpData, setBirpData] = useState({});
  const [birpSuggestions, setBirpSuggestions] = useState({});

  const [girpData, setGirpData] = useState({});
  const [girpSuggestions, setGirpSuggestions] = useState({});

  const [sessionStartTime, setSessionStartTime] = useState(null);
  const audioChunksRef = useRef([]);
  const audioChunksPCMRef = useRef([]);

  const [languageSelectModal, setLanguageSelectModal] = useState(false);
  const transcriptLang = useSelector(
    (store) => store.liveSession.transcriptLang
  );
  const handleLanguageModalOpen = () => setLanguageSelectModal(true);
  const handleLanguageModalClose = () => {
    setLanguageSelectModal(false);
  };
  const handleLanguageModalSave = () => {
    setRealtimeButton(true);
    setLanguageSelectModal(false);
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const therapy_id = queryParameters.get("therapy_id");
  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  const speakerLabel = useSelector((state) => state.liveSession.speakerLabel);
  const realtime_session_id = useSelector(
    (state) => state.liveSession.liveSessionId
  );
  const transcript = useSelector((store) => store.liveSession.transcript);
  const transcriptArr = useSelector((store) => store.liveSession.transcriptArr);
  const microphoneStatus = useSelector(
    (store) => store.liveSession.microphoneStatus
  );
  const sentimentScores = useSelector(
    (store) => store.liveSession.clientSentimentScore
  );

  const [fakeValue, setFakeValue] = useState(0);

  // const parseTimeFromTranscriptArr = (inputArr) => {
  //   const outputArray = inputArr.map((item) => {
  //     const matches = item.content.match(/\{(\d+(\.\d+)?),(\d+(\.\d+)?)\}/g);

  //     let cleanedContent = item.content;
  //     let start = null;
  //     let end = null;

  //     if (matches) {
  //       matches.forEach((match) => {
  //         const [substring, startTime, , endTime] =
  //           /\{(\d+(\.\d+)?),(\d+(\.\d+)?)\}/.exec(match);

  //         cleanedContent = cleanedContent.replace(substring, "");
  //         if (start === null || parseFloat(startTime) < start) {
  //           start = parseFloat(startTime);
  //         }
  //         if (end === null || parseFloat(endTime) > end) {
  //           end = parseFloat(endTime);
  //         }
  //       });
  //     }

  //     return {
  //       speaker: item.speaker,
  //       content: cleanedContent.trim(),
  //       start,
  //       end,
  //     };
  //   });

  //   return outputArray;
  // };

  useEffect(() => {
    const generateFakeValue = () => {
      setFakeValue((prevFakeValue) => {
        if (prevFakeValue < 5) {
          return prevFakeValue + 1;
        }
        return prevFakeValue;
      });
    };

    let fakeValueInterval;
    if (showReportLoading && reportProgress === 0) {
      fakeValueInterval = setInterval(generateFakeValue, 2000);
      return () => clearInterval(fakeValueInterval);
    }

    setFakeValue(0);
  }, [showReportLoading]);

  // useEffect(() => {
  //   if (transcriptArr.length > 2 && transcriptArr.length % 2 === 0) {
  //     determineRoleFromTranscript(
  //       therapist_id,
  //       generateTranscriptTextFromArray(transcriptArr)
  //     )
  //       .then((res) => {
  //         // console.log("Diarized data: ", res.data)
  //         dispatch(setSpeakerLabel(res.data));
  //       })
  //       .catch((err) => {
  //         console.log("error is : ", err);
  //       });
  //   }
  //   console.log(transcriptArr);
  // }, [transcriptArr]);

  const save_sentiment_scores = async (realtime_session_id) => {
    const sentiment_scores = sentimentScores.map((sentiment_score) => ({
      ...sentiment_score,
      realtime_session_id,
    }));
    const data = await saveSentimentScoresAPI(sentiment_scores);
    return data;
  };

  const saveRealtimeAudio = async (real_time_session_id) => {

    let file;
    let audio_type;

    if (audioChunksRef.current.length > 0) {
      file = new Blob([...audioChunksRef.current], {
        type: "audio/webm",
      });
      audio_type = "webm"
    } else if (audioChunksPCMRef.current.length > 0) {
      file = new Blob([...audioChunksPCMRef.current], {
        type: "application/octet-stream",
      });
      audio_type = "pcm"
    } else {
      console.warn("No audio chunks to upload.");
      return;
    }

    let response = await getRealtimeAudioUploadURLAPI(
      therapy_id,
      real_time_session_id,
      audio_type,
    );

    const data = response.data;
    const presignedUrl = data.url;

    await uploadFiles(presignedUrl, file);
    const res = await saveRealtimeAudioAPI(
      real_time_session_id,
      data.bucket,
      data.key
    );
  };

  const onRealtimeSessionClick = async (transcript) => {
    if (!realtimeButton) {
      console.log("Cleare the note state --- ");
      dispatch(setLiveSessionId(null));
      dispatch(removeClientSentimentScore());
      setSessionStartTime(new Date());
      handleLanguageModalOpen();
      return;
    }

    // this should be the session close button
    if (realtimeButton && transcript.length !== 0) {
      setShowReportLoading(true);

      // let temp_real_time_session_id;
      // if (realtime_session_id === null) {
      //   let res = await createRealtimeSession(therapist_id, therapy_id);
      //   let res1 = await saveRealtimeTranscript(
      //     therapist_id,
      //     therapy_id,
      //     res.data.real_time_session_id,
      //     convertTranscriptArrForSaving(transcriptArr, speakerLabel)
      //   );
      //   await saveRealtimeAudio(res.data.real_time_session_id);
      //   console.log("Saved the transcript: ", res1);

      //   // call the transcript saving API here
      //   console.log("result from create live Session: ", res);
      //   dispatch(setLiveSessionId(res.data.real_time_session_id));
      //   dispatch(setNoteId(res.data.progress_note_id));
      //   temp_real_time_session_id = res.data.real_time_session_id;
      // } else {
      //   temp_real_time_session_id = realtime_session_id;
      //   let res1 = await saveRealtimeTranscript(
      //     therapist_id,
      //     temp_real_time_session_id,
      //     convertTranscriptArrForSaving(transcriptArr, speakerLabel)
      //   );
      //   await saveRealtimeAudio(temp_real_time_session_id);
      //   console.log("Saved the transcript: ", res1);
      // }

      // save the session start and endtime here
      // console.log(
      //   "Session start time is: ",
      //   formatDateToYYYYMMDDHHMMSS(sessionStartTime)
      // );
      // console.log(
      //   "Session end time is: ",
      //   formatDateToYYYYMMDDHHMMSS(new Date())
      // );
      // updateDurationOfRealtimeSession(
      //   temp_real_time_session_id,
      //   formatDateToYYYYMMDDHHMMSS(sessionStartTime),
      //   formatDateToYYYYMMDDHHMMSS(new Date())
      // );

      let temp_real_time_session_id;
      if (realtime_session_id === null) {
        const res = await createRealtimeSession(therapist_id, therapy_id);
        temp_real_time_session_id = res.data.real_time_session_id;
        dispatch(setLiveSessionId(temp_real_time_session_id));
        dispatch(setNoteId(res.data.progress_note_id));
      } else {
        temp_real_time_session_id = realtime_session_id;
      }

      console.log("temp_real_time_session_id", temp_real_time_session_id)

      const reports = [
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "SOAP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "DAP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "BIRP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "GIRP",
          temp_real_time_session_id
        ),
      ];

      const promisesWithIndex = reports.map((promise, index) => ({
        promise,
        index,
      }));

      const allPromises = promisesWithIndex.map(({ promise, index }) =>
        promise
          .then((result) => {
            setReportProgress((prevProgress) => prevProgress + 1);
            console.log(`Task ${index} finished:`, result);
            return { index, result };
          })
          .catch((error) => {
            setReportProgress((prevProgress) => prevProgress + 1);
            console.error(`Task ${index} failed:`, error);
            throw error;
          })
      );

      // save_sentiment_scores(temp_real_time_session_id)
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((err) => console.log(err));

      Promise.all(allPromises)
        .then((fulfilledResults) => {
          // All promises have resolved
          console.log("All tasks finished:", fulfilledResults);

          fulfilledResults.forEach((response, index) => {
            console.log(
              `This is the Response ${index + 1}: `,
              response.result.data
            );
          });

          const {
            progressNotesFormatted: soapNotesFormatted,
            suggestionsFormatted: soapSuggestionsFormatted,
          } = processAiReport(fulfilledResults[0].result.data, "SOAP");
          setSoapData(soapNotesFormatted);
          setSoapSuggestions(soapSuggestionsFormatted);

          const {
            progressNotesFormatted: dapNotesFormatted,
            suggestionsFormatted: dapSuggestionsFormatted,
          } = processAiReport(fulfilledResults[1].result.data, "DAP");
          setDapData(dapNotesFormatted);
          setDapSuggestions(dapSuggestionsFormatted);

          const {
            progressNotesFormatted: birpNotesFormatted,
            suggestionsFormatted: birpSuggestionsFormatted,
          } = processAiReport(fulfilledResults[2].result.data, "BIRP");
          setBirpData(birpNotesFormatted);
          setBirpSuggestions(birpSuggestionsFormatted);

          const {
            progressNotesFormatted: girpNotesFormatted,
            suggestionsFormatted: girpSuggestionsFormatted,
          } = processAiReport(fulfilledResults[3].result.data, "GIRP");
          setGirpData(girpNotesFormatted);
          setGirpSuggestions(girpSuggestionsFormatted);

          setShowReportLoading(false);
          setRealtimeReportModal(true);
          dispatch(clearTranscript());
          // dispatch(setLiveSessionId(null));
          setReportProgress(0);
        })
        .catch((error) => {
          console.log("The error is: ", error);
          dispatch(clearTranscript());
          // dispatch(setLiveSessionId(null));
          setShowReportLoading(false);
          setReportProgress(0);
          alert("Something went wrong");
        });

      console.log(
        "Session start time is: ",
        formatDateToYYYYMMDDHHMMSS(sessionStartTime)
      );
      console.log(
        "Session end time is: ",
        formatDateToYYYYMMDDHHMMSS(new Date())
      );
      updateDurationOfRealtimeSession(
        temp_real_time_session_id,
        formatDateToYYYYMMDDHHMMSS(sessionStartTime),
        formatDateToYYYYMMDDHHMMSS(new Date())
      );


      let res1 = await saveRealtimeTranscript(
        therapist_id,
        therapy_id,
        temp_real_time_session_id,
        convertTranscriptArrForSaving(transcriptArr, speakerLabel)
      );
      await saveRealtimeAudio(temp_real_time_session_id);
      console.log("Saved the transcript: ", res1);

      save_sentiment_scores(temp_real_time_session_id)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => console.log(err));


    }
    setRealtimeButton(!realtimeButton);
  };

  const onViewTranscriptClick = () => {
    setTranscriptViewPanel(!transcriptViewPanel);
  };

  const onVoiceRecognitionStopClick = () => {
    setTranscriptViewPanel(false);
  };

  const onRealtimeReportModalClose = () => {
    setRealtimeReportModal(!realtimeReportModal);
    dispatch(clearRealtimeSessionstore());
  };

  return (
    <>
      <Dialog
        open={languageSelectModal}
        onClose={handleLanguageModalClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            maxWidth: "400px",
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Therapy Language</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Select
              labelId="language-label"
              id="language"
              value={transcriptLang}
              onChange={(e) => dispatch(setTranscriptLanguage(e.target.value))}
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"hi"}>Hindi</MenuItem>
              <MenuItem value={"ja"}>Japanese</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLanguageModalSave}
            variant="contained"
            color="primary"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className={
          realtimeButton || microphoneStatus
            ? "client-chart-body-realtime-transcript"
            : "client-chart-body"
        }
      >
        {realtimeReportModal ? (
          <RealtimeSessionReport
            soapData={soapData}
            soapSuggestions={soapSuggestions}
            dapData={dapData}
            dapSuggestions={dapSuggestions}
            birpData={birpData}
            birpSuggestions={birpSuggestions}
            girpData={girpData}
            girpSuggestions={girpSuggestions}
            onClose={onRealtimeReportModalClose}
          />
        ) : (
          showReportLoading && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                top: "0",
                left: "0",
                height: "100vh",
                width: "100vw",
                zIndex: "999",
                background: "rgba(255, 255, 255, 0.5)",
                backdropFilter: "blur(4px)",
              }}
            >
              <img src={ProgressReportLoader} alt="loader" />
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                Generating Progress Notes...
              </p>
              <div
                style={{
                  width: "540px",
                  height: "20px",
                }}
              >
                <ProgressBar
                  progress={
                    reportProgress === 0
                      ? fakeValue
                      : (reportProgress / 4) * 100
                  }
                  barColor={"#5F6CE1"}
                />
              </div>
            </div>
          )
        )}

        <ClientDetailsContainer
          realtimeButtonState={!realtimeButton}
          onRealtimeSessionClick={onRealtimeSessionClick}
        />

        {(realtimeButton || microphoneStatus) && (
          <>
            {transcriptLang === "ja-JP" || transcriptLang === "hi-IN" ? (
              <RealtimePanelAWS
                onViewTranscriptClick={onViewTranscriptClick}
                onSessionStopClick={onVoiceRecognitionStopClick}
                realtimeButtonState={realtimeButton}
                transcriptViewPanel={transcriptViewPanel}
                audioChunksRef={audioChunksPCMRef}
              />
            ) : (
              // <RealtimePanel
              <RealtimePanelAWS
                onViewTranscriptClick={onViewTranscriptClick}
                onSessionStopClick={onVoiceRecognitionStopClick}
                realtimeButtonState={realtimeButton}
                transcriptViewPanel={transcriptViewPanel}
                audioChunksRef={audioChunksPCMRef}
              />

              // <RealtimePanel
              //   onViewTranscriptClick={onViewTranscriptClick}
              //   onSessionStopClick={onVoiceRecognitionStopClick}
              //   realtimeButtonState={realtimeButton}
              //   transcriptViewPanel={transcriptViewPanel}
              //   audioChunksRef={audioChunksRef}
              // />
            )}
          </>
        )}

        {transcriptViewPanel && realtimeButton ? (
          <TranscriptPanel onPanelClose={onViewTranscriptClick} />
        ) : (
          <ClientUtilitiesTab realtimeButton={realtimeButton} /> //realtimeButton && transcript.length !== 0
        )}
      </div>
    </>
  );
};
export default ClientChart;
