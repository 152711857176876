import React, { useEffect, useRef, useState } from "react";
import ASSETS from "../../../../assets/Assets";
import {
  getRealtimeAudioUrlAPI,
  getRealtimeSessionDetailsAPI,
  getTranscriptUrl,
  getTranscriptUrlAPI,
} from "../../../../api/realtime";
import { useDispatch, useSelector } from "react-redux";
import { read_report } from "../../../../api/notes";
import { useSearchParams } from "react-router-dom";
import WavesurferPlayer from "@wavesurfer/react";
import "./RealtimeSessionDetails.css";
import {
  ExpandLess,
  ExpandMore,
  PauseCircleFilled,
  PlayCircleFilled,
} from "@mui/icons-material";
import RealtimeSessionSentiments from "../RealtimeSession/RealtimeSessionSentiments";
import axios from "axios";
import RealtimeSessionReport from "../../../dashboard/components/clientChart/RealtimePanel/RealtimeSessionReport/RealtimeSessionReport";
import { MdModeEdit } from "react-icons/md";
import RealtimeSessionSoap from "../RealtimeSession/RealtimeSessionSoap";
import RealtimeSessionBirp from "../RealtimeSession/RealtimeSessionBirp";
import RealtimeSessionGirp from "../RealtimeSession/RealtimeSessionGirp";
import RealtimeSessionDap from "../RealtimeSession/RealtimeSessionDap";

import { MenuItem, Select } from "@mui/material";
import TherapistReportV2 from "./TherapistReportV2/TherapistReportV2";
import { Loader2 } from "lucide-react";

const therapistChatStyle = {
  borderRadius: "1rem",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #F4F4F4",
  padding: "0.75rem 1.5rem",
  width: "fit-content",
  marginLeft: "auto",
  maxWidth: "70%",
};

const patientChatStyle = {
  borderRadius: "1rem",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #DDE2E9",
  padding: "0.75rem 1.5rem",
  width: "fit-content",
  maxWidth: "70%",
};

function RealtimeSessionDetails({ setIndex, videoId, currentSession }) {
  const therapistId = useSelector((store) => store.therapist.therapist_id);
  const [searchParams] = useSearchParams();
  const therapyId = searchParams.get("therapy_id");
  const audioContainerRef = useRef(null);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [realtimeSession, setRealtimeSession] = useState(null);
  const [realtimeSessionProgressNotes, setRealtimeSessionProgressNotes] =
    useState(null);
  const [sentimentChartExpanded, setSentimentChartExpanded] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [transcript, setTranscript] = useState("[]");
  const [audioURL, setAudioURL] = useState("");
  const [loading, setLoading] = useState(true);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };

  const [showProgressNotesEditModal, setShowProgressNotesEditModal] =
    useState(false);
  const [selectedReportFormat, setSelectedReportFormat] = useState("soap");

  console.log("realtimeSessionProgressNotes", realtimeSessionProgressNotes)
  const fetchAllData = async () => {
    const realtimeSessionDetailsPromise = getRealtimeSessionDetailsAPI(
      therapistId,
      videoId
    );
    const progressNotesPromise = read_report(
      therapyId,
      currentSession.progress_note_id
    );
    // const transcriptUrlPromise = getTranscriptUrl(
    //   therapistId,
    //   videoId,
    //   therapyId
    // );

    // const [realtimeSessionRes, progressNotesRes, transcriptUrlRes] =
    //   await Promise.all([
    //     realtimeSessionDetailsPromise,
    //     progressNotesPromise,
    //     transcriptUrlPromise,
    //   ]);

    const [realtimeSessionRes, progressNotesRes] = await Promise.all([
      realtimeSessionDetailsPromise,
      progressNotesPromise,
    ]);

    setRealtimeSession(realtimeSessionRes.data);
    setRealtimeSessionProgressNotes(progressNotesRes);
    setTranscript(realtimeSessionRes.data.transcript);
    setLoading(false);

    if (realtimeSessionRes.data?.audio_bucket_key) {
      const audioData = await getRealtimeAudioUrlAPI(
        therapistId,
        realtimeSessionRes.data?.audio_bucket_key
      );
      setAudioURL(audioData.data);
    }

    // const res1 = await axios.get(transcriptUrlRes.data, {
    //   responseType: "blob",
    // });
    // let htmlContent = "";
    // const reader = new FileReader();
    // reader.onload = function () {
    //   htmlContent = reader.result;
    //   setTranscript(htmlContent);
    //   setLoading(false);
    // };
    // reader.readAsText(res1.data);
  };
  useEffect(() => {
    if (videoId) {
      fetchAllData();
    }
  }, [videoId]);

  const [currentPage, setCurrentPage] = useState("Client");
  const handlePageChange = (event) => {
    setCurrentPage(event.target.value);
  };

  return (
    <div className="realtime-session-container">
      <div className="report-session-header row">
        <div className="row justify-flex-start">
          <div onClick={() => setIndex(0)} className="report-session-back-btn">
            <img src={ASSETS.circularBackBtn} alt="circular back btn" />
          </div>
          <div className="report-session-heading">All Sessions</div>
        </div>
        <div>
          <div
            className="row justify-flex-start"
            style={{ alignItems: "center", gap: "1rem" }}
          >
            <div>Report For:</div>
            <Select value={currentPage} onChange={handlePageChange}>
              <MenuItem value={"Client"}>Client</MenuItem>
              <MenuItem value={"Therapist"}>Therapist</MenuItem>
            </Select>
          </div>
        </div>
      </div>
      {currentPage === "Therapist" && (
        <TherapistReportV2
          therapistId={therapistId}
          realtimeSessionId={videoId}
        />
      )}
      {currentPage === "Client" && (
        <div className="realtime-session-wrapper">
          {audioURL === "" ? (
            <div
              style={{
                width: "100%",
                minHeight: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader2 className="h-6 w-6 animate-spin" />
            </div>
          ) : (
            <div className="realtime-session-audio" onClick={onPlayPause}>
              <div className="realtime-session-audio-play-pause">
                {!isPlaying && (
                  <PlayCircleFilled
                    style={{
                      color: "rgba(95, 108, 225, 1)",
                      width: "4rem",
                      height: "4rem",
                    }}
                  />
                )}
                {isPlaying && (
                  <PauseCircleFilled
                    style={{
                      color: "rgba(236, 116, 64, 1)",
                      width: "4rem",
                      height: "4rem",
                    }}
                  />
                )}
              </div>
              <div className="realtime-session-audio-wavesurfer-wrapper">
                <WavesurferPlayer
                  height="auto"
                  waveColor="#dde2e9"
                  url={audioURL}
                  onReady={onReady}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  progressColor="#5f6ce1"
                  dragToSeek={true}
                  barGap={6}
                  barWidth={3}
                  barAlign="bottom"
                  barHeight={2}
                  autoScroll={true}
                />
              </div>
            </div>
          )
          }

          {loading ? (
            <div
              style={{
                width: "100%",
                minHeight: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader2 className="h-6 w-6 animate-spin" />
            </div>
          ) : (
            <>
              <div className="realtime-session-client-sentiment-score">
                {realtimeSession && (
                  <RealtimeSessionSentiments
                    clientSentimentScore={realtimeSession.sentiments}
                  />
                )}
              </div>
              <div className="realtime-session-progress-notes-transcript-wrapper">
                <div className="realtime-session-progress-notes-transcript-header">
                  <div
                    className={`sub-heading ${selectedTab === 0 && "sub-heading-selected"
                      }`}
                    onClick={() => {
                      setSelectedTab(0);
                    }}
                  >
                    Progress Notes
                    <MdModeEdit
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() => {
                        setShowProgressNotesEditModal(true);
                      }}
                    />
                  </div>
                  <div
                    className={`sub-heading ${selectedTab === 1 && "sub-heading-selected"
                      }`}
                    onClick={() => setSelectedTab(1)}
                  >
                    Transcript
                  </div>
                </div>
                <div className="realtime-session-progress-notes-transcript-body scroll-thin-vertical">
                  {selectedTab === 0 && (
                    <>
                      {selectedReportFormat === "soap" && (
                        <RealtimeSessionSoap
                          soap={realtimeSessionProgressNotes?.soap}
                        />
                      )}
                      {selectedReportFormat === "birp" && (
                        <RealtimeSessionBirp
                          birp={realtimeSessionProgressNotes?.birp}
                        />
                      )}
                      {selectedReportFormat === "girp" && (
                        <RealtimeSessionGirp
                          girp={realtimeSessionProgressNotes?.girp}
                        />
                      )}
                      {selectedReportFormat === "dap" && (
                        <RealtimeSessionDap dap={realtimeSessionProgressNotes?.dap} />
                      )}
                    </>
                  )}
                  {selectedTab === 1 && (
                    <pre
                      //   className="scroll-thin-vertical"
                      style={{
                        padding: "2rem",
                        whiteSpace: "pre-wrap",
                        // marginTop: "20px",
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                      }}
                    >
                      {/* {transcript} */}
                      {transcript.map((item, index) => {
                        if (item.role === "therapist") {
                          return (
                            <div key={index} style={{ ...therapistChatStyle }}>
                              <p
                                style={{
                                  color: "#282860",
                                  margin: 0,
                                  fontWeight: "600",
                                  fontSize: "1rem",
                                  textAlign: "right",
                                }}
                              >
                                You
                              </p>
                              <p
                                style={{
                                  color: "#282860",
                                  margin: 0,
                                  fontSize: "0.875rem",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  textAlign: "right",
                                  opacity: 0.8,
                                }}
                              >
                                {item.content}
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div key={index} style={{ ...patientChatStyle }}>
                              <p
                                style={{
                                  color: "#282860",
                                  margin: 0,
                                  fontWeight: "600",
                                  fontSize: "1rem",
                                }}
                              >
                                {item.role.charAt(0).toUpperCase() +
                                  item.role.slice(1)}
                              </p>
                              <p
                                style={{
                                  color: "#282860",
                                  margin: 0,
                                  fontSize: "0.875rem",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  opacity: 0.8,
                                }}
                              >
                                {item.content}
                              </p>
                            </div>
                          );
                        }
                      })}
                    </pre>
                  )}
                </div>
              </div>
              {showProgressNotesEditModal && realtimeSessionProgressNotes && (
                <RealtimeSessionReport
                  soapData={{
                    subjective: realtimeSessionProgressNotes?.soap.subjective,
                    objective: realtimeSessionProgressNotes?.soap.objective,
                    assessment: realtimeSessionProgressNotes?.soap.assessment,
                    plan: realtimeSessionProgressNotes?.soap.plan,
                    summary: realtimeSessionProgressNotes?.soap.summary,
                  }}
                  soapSuggestions={{
                    subjective: JSON.parse(
                      realtimeSessionProgressNotes?.soap.subjective_suggestions
                    ),
                    objective: JSON.parse(
                      realtimeSessionProgressNotes?.soap.objective_suggestions
                    ),
                    assessment: JSON.parse(
                      realtimeSessionProgressNotes?.soap.assessment_suggestions
                    ),
                    plan: JSON.parse(
                      realtimeSessionProgressNotes?.soap.plan_suggestions
                    ),
                  }}
                  dapData={{
                    data: realtimeSessionProgressNotes?.dap.data,
                    assessment: realtimeSessionProgressNotes?.dap.assessment,
                    plan: realtimeSessionProgressNotes?.dap.plan,
                    summary: realtimeSessionProgressNotes?.dap.summary,
                  }}
                  dapSuggestions={{
                    data: JSON.parse(
                      realtimeSessionProgressNotes?.dap.data_suggestions
                    ),
                    assessment: JSON.parse(
                      realtimeSessionProgressNotes?.dap.assessment_suggestions
                    ),
                    plan: JSON.parse(
                      realtimeSessionProgressNotes?.dap.plan_suggestions
                    ),
                  }}
                  birpData={{
                    subjective: realtimeSessionProgressNotes?.birp.behavior,
                    intensity: realtimeSessionProgressNotes?.birp.intensity,
                    response: realtimeSessionProgressNotes?.birp.response,
                    plan: realtimeSessionProgressNotes?.birp.plan,
                    summary: realtimeSessionProgressNotes?.birp.summary,
                  }}
                  birpSuggestions={{
                    behavior: JSON.parse(
                      realtimeSessionProgressNotes?.birp.behavior_suggestions
                    ),
                    intensity: JSON.parse(
                      realtimeSessionProgressNotes?.birp.intensity_suggestions
                    ),
                    response: JSON.parse(
                      realtimeSessionProgressNotes?.birp.response_suggestions
                    ),
                    plans: JSON.parse(
                      realtimeSessionProgressNotes?.birp.plan_suggestions
                    ),
                  }}
                  girpData={{
                    goals: realtimeSessionProgressNotes?.girp.goals,
                    interventions: realtimeSessionProgressNotes?.girp.intensity,
                    response: realtimeSessionProgressNotes?.girp.response,
                    plan: realtimeSessionProgressNotes?.girp.plan,
                    summary: realtimeSessionProgressNotes?.girp.summary,
                  }}
                  girpSuggestions={{
                    goals: JSON.parse(
                      realtimeSessionProgressNotes?.girp.goals_suggestions
                    ),
                    interventions: JSON.parse(
                      realtimeSessionProgressNotes?.girp.interventions_suggestions
                    ),
                    response: JSON.parse(
                      realtimeSessionProgressNotes?.girp.response_suggestions
                    ),
                    plans: JSON.parse(
                      realtimeSessionProgressNotes?.girp.plan_suggestions
                    ),
                  }}
                  onClose={() => setShowProgressNotesEditModal(false)}
                  note_id={realtimeSessionProgressNotes.note_id}
                  transcriptProp={transcript}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default RealtimeSessionDetails;
