import React, { useState } from 'react';
import {
  ArrowLeft,
  ArrowRight,
  Mic,
  Square,
  Mail,
  Phone,
  MapPin,
  Cake,
  Edit2,
  Check,
  Clock,
  Users,
  Calendar,
  Activity,
  BookOpen,
  BrainCircuit,
  Target,
  FileText,
  Milestone
} from "lucide-react";

// Basic Button Component
const Button = ({ children, className = "", ...props }) => (
  <button
    className={`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 ${className}`}
    {...props}
  >
    {children}
  </button>
);

// Basic Input Component
const Input = ({ className = "", ...props }) => (
  <input
    className={`flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm transition-colors focus:outline-none focus:ring-1 focus:ring-indigo-500 ${className}`}
    {...props}
  />
);

// Basic Textarea Component
const Textarea = ({ className = "", ...props }) => (
  <textarea
    className={`flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-indigo-500 ${className}`}
    {...props}
  />
);

// Basic Switch Component
const Switch = ({ checked, onChange, className = "" }) => (
  <button
    role="switch"
    aria-checked={checked}
    onClick={() => onChange(!checked)}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${checked ? 'bg-indigo-600' : 'bg-gray-200'
      } ${className}`}
  >
    <span
      className={`${checked ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
    />
  </button>
);

// Custom Tabs Components
const Tabs = ({ children, value, onChange }) => (
  <div className="w-full">{children}</div>
);

const TabsList = ({ children }) => (
  <div 
  className="flex gap-6 px-6 pt-4 pb-3"
  style={{ borderBottom: "1px solid #ccc" }}
  >
    {children}
  </div>
);


const TabsTrigger = ({ children, value, isActive, onClick }) => (
  <button
    onClick={() => onClick(value)}
    className={`flex items-center px-4 py-2 rounded-md transition-all ${isActive
      ? 'bg-gradient-to-r from-indigo-600 to-indigo-700 text-white shadow-sm'
      : 'text-gray-600 hover:bg-white hover:text-gray-900'
      }`}
  >
    {children}
  </button>
);

const TabsContent = ({ children, value, activeValue }) => (
  value === activeValue ? <div>{children}</div> : null
);

const LeadChart = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [activeTab, setActiveTab] = useState("summary");
  const [sessionSummary, setSessionSummary] = useState({
    questions: "1. How can I manage my work-life balance better?\n2. What techniques can I use to reduce stress?\n3. How can I communicate better with my family?",
    primaryConcern: "Work-related anxiety and family stress",
    readyFor60Min: "yes",
    notInterestedReason: "Client feels they need more time to think about their goals",
    agreedDate: "2024-12-25"
  });

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-white to-slate-50">
      <div className="w-full max-w-[1440px] mx-auto px-6 lg:px-8 py-8">
        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-1/3 h-64 bg-gradient-to-bl from-indigo-100/20 via-amber-100/20 to-transparent -z-10" />
        <div className="absolute bottom-0 left-0 w-1/2 h-96 bg-gradient-to-tr from-indigo-100/20 via-amber-100/20 to-transparent -z-10" />

        {/* Back Button */}
        <button onClick={() => window.history.back()}
          className="appearance-none bg-transparent border-none p-0 m-0 flex items-center text-gray-600 hover:text-gray-900 mb-6 font-medium text-lg cursor-pointer"
        >
          <ArrowLeft className="mr-2" size={20} />
          Back to client list
        </button>

        {/* Main Layout */}
        <div className="grid grid-cols-12 gap-8">
          {/* Left Column */}
          <div className="col-span-3 flex flex-col">
            <div className="rounded-lg border border-indigo-100 shadow-lg bg-gradient-to-b from-indigo-50 to-white flex-grow relative overflow-hidden p-6">
              {/* Profile Content */}
              <div className="flex flex-col items-center text-center">
                {/* Profile Icon */}
                <div className="mt-4 mb-6 relative">
                  <div className="w-32 h-32 rounded-full flex items-center justify-center text-white text-2xl font-semibold bg-gradient-to-br from-indigo-600 to-indigo-700 shadow-lg">
                    AT
                  </div>
                  <div className="absolute -bottom-2 -right-2 bg-green-500 rounded-full p-2 shadow-lg">
                    <Users size={16} className="text-white" />
                  </div>
                </div>

                <h1 className="text-2xl font-bold text-gray-900 mb-2">Aryaman Thakur</h1>

                {/* Recording Button */}
                <div className="w-full my-6">
                  <Button
                    onClick={toggleRecording}
                    className={`w-full gap-2 h-12 border-0 ${isRecording
                      ? "bg-rose-500 hover:bg-rose-600 text-white"
                      : "bg-indigo-600 hover:bg-indigo-700 text-white"
                      } shadow-lg transition-all duration-200`}
                  >
                    {isRecording ? (
                      <>
                        <Square className="w-5 h-5" />
                        Stop Recording
                      </>
                    ) : (
                      <>
                        <Mic className="w-5 h-5" />
                        Start Recording
                      </>
                    )}
                  </Button>
                  {isRecording && (
                    <div className="text-rose-500 text-sm font-medium text-center mt-2 flex items-center justify-center">
                      <Activity size={14} className="mr-1" />
                      Recording in progress...
                    </div>
                  )}
                </div>

                {/* Profile Info */}
                <div className="w-full space-y-4 mt-2">
                  {[
                    { icon: Mail, text: "aryaman.thakur999888@gmail.com" },
                    { icon: Phone, text: "+81 90-1234-5678" },
                    { icon: MapPin, text: "Tokyo, Japan" },
                    { icon: Cake, text: "1990/01/01 (34 years old)" }
                  ].map(({ icon: Icon, text }, index) => (
                    <div key={index} className="flex items-center gap-3 text-left p-2 hover:bg-indigo-50 rounded-lg transition-colors">
                      <Icon className="w-4 h-4 text-indigo-600" />
                      <span className="text-sm text-gray-600">{text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col-span-9 flex flex-col">
            <div className="rounded-lg border border-indigo-100 shadow-lg flex-grow">
              {/* Navigation Header */}
              <div className="px-6 py-4 bg-gradient-to-r from-indigo-600 to-indigo-700 text-white rounded-t-lg relative overflow-hidden">
                <div className="absolute top-0 right-0 w-64 h-full bg-gradient-to-l from-white/10 to-transparent" />
                <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-white/50 to-transparent" />

                <div className="flex items-center justify-between relative">
                  <div className="flex gap-4">
                    {["Previous", "Next"].map((text, index) => (
                      <Button
                        key={text}
                        className="bg-white/10 hover:bg-white/20 text-white border-0 px-4 py-2 rounded-md"
                      >
                        {index === 0 && <ArrowLeft className="mr-2" size={16} />}
                        {text}
                        {index === 1 && <ArrowRight className="ml-2" size={16} />}
                      </Button>
                    ))}
                  </div>
                  <div className="flex items-center gap-2 text-indigo-100">
                    <Calendar size={16} />
                    <p className="text-sm font-medium">Session Date: 2024-12-18</p>
                  </div>
                </div>
              </div>

              {/* Tabs */}
              <TabsList>
                {[
                  { value: "summary", icon: BookOpen, label: "Session Summary" },
                  { value: "transcript", icon: BrainCircuit, label: "Transcript" }
                ].map(({ value, icon: Icon, label }) => (
                  <button
                    key={value}
                    onClick={() => setActiveTab(value)}
                    className={`appearance-none bg-transparent border-none py-1 m-0 
                  flex items-center text-medium font-medium transition-all pb-1 cursor-pointer
                  ${activeTab === value
                        ? "text-indigo-600 border-b-2 border-indigo-600"
                        : "text-gray-600 hover:text-indigo-600"
                      }`}
                  >
                    <Icon className="w-4 h-4 mr-2" />
                    {label}
                  </button>
                ))}
              </TabsList>

              {/* Main Content */}
              <TabsContent value="summary" activeValue={activeTab}>
                <div className="p-6 space-y-8">
                  {/* Header with Edit Button */}
                  <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-3">
                      <div>
                        <h2 className="text-2xl font-bold text-indigo-900 tracking-tight">Session Summary</h2>
                        {/* <p className="text-sm text-gray-500 mt-1">Document key insights and next steps</p> */}
                      </div>
                    </div>
                    <Button
                      onClick={handleEdit}
                      className={`gap-2 shadow-sm ${isEditing
                        ? "bg-amber-500 hover:bg-amber-600 text-white"
                        : "text-amber-600 border-2 border-amber-200 hover:bg-amber-50"
                        }`}
                    >
                      <Edit2 size={16} />
                      {isEditing ? "Save Changes" : "Edit Summary"}
                    </Button>
                  </div>
                  {/* Next Steps Section */}
                  <div className="p-4 bg-indigo-50/50 rounded-lg border border-indigo-100">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-12 h-12 rounded-lg bg-indigo-600 flex items-center justify-center">
                        <Milestone className="w-7 h-7 text-white" />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold text-indigo-900">Next Steps</h3>
                        {/* <p className="text-sm text-indigo-600">Current status and action items</p> */}
                      </div>
                    </div>

                    {isEditing ? (
                      <div className="space-y-4">
                        <div className="flex items-center gap-4">
                          <Switch
                            checked={sessionSummary.readyFor60Min === "yes"}
                            onChange={(checked) => setSessionSummary({
                              ...sessionSummary,
                              readyFor60Min: checked ? "yes" : "no"
                            })}
                          />
                          <label className="font-medium text-gray-700">
                            Ready for full therapy session
                          </label>
                        </div>

                        {sessionSummary.readyFor60Min === "yes" ? (
                          <div className="bg-white p-4 rounded-lg">
                            <label className="text-sm mb-2 block font-medium">
                              Preferred Session Date
                            </label>
                            <Input
                              type="date"
                              value={sessionSummary.agreedDate}
                              onChange={(e) => setSessionSummary({
                                ...sessionSummary,
                                agreedDate: e.target.value
                              })}
                              className="w-full p-2 rounded-md border border-indigo-200 focus:ring-2 focus:ring-indigo-500"
                            />
                          </div>
                        ) : (
                          <div className="bg-white p-4 rounded-lg">
                            <label className="text-sm mb-2 block font-medium">
                              Client's Current Thoughts
                            </label>
                            <Textarea
                              value={sessionSummary.notInterestedReason}
                              onChange={(e) => setSessionSummary({
                                ...sessionSummary,
                                notInterestedReason: e.target.value
                              })}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {sessionSummary.readyFor60Min === "yes" ? (
                          <div className="flex items-center gap-4 bg-white p-4 rounded-lg border border-green-100">
                            <div className="w-14 h-14 bg-green-100 rounded-full flex items-center justify-center">
                              <Check className="w-8 h-8 text-green-500" />
                            </div>
                            <div>
                              <p className="font-bold text-green-700">Ready to proceed</p>
                              <p className="text-gray-600">
                                Next session: {new Date(sessionSummary.agreedDate).toLocaleDateString()}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center gap-4 bg-white p-4 rounded-lg border border-amber-100">
                            <div className="w-14 h-14 bg-amber-100 rounded-full flex items-center justify-center">
                              <Clock className="w-8 h-8 text-amber-500" />
                            </div>
                            <div>
                              <p className="font-bold text-amber-700">Needs more time</p>
                              <p className="text-gray-600">{sessionSummary.notInterestedReason}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Session Overview Section - White textarea, darker surrounding */}
                  <div className="">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-indigo-200">
                        <FileText className="w-5 h-5 text-indigo-600" />
                      </div>
                      <h3 className="text-lg font-semibold text-indigo-900">
                        Session Overview
                      </h3>
                    </div>
                    {isEditing ? (
                      <Textarea
                        value={sessionSummary.overview}
                        onChange={(e) => setSessionSummary({
                          ...sessionSummary,
                          overview: e.target.value
                        })}
                        className="w-full bg-white"
                        rows={4}
                        placeholder="Provide a brief overview of the session, including key observations and general progress..."
                      />
                    ) : (
                      <div className="bg-white px-4 rounded-lg border border-indigo-200">
                        <p className="text-gray-700 leading-relaxed">
                          {sessionSummary.overview || "No session overview provided yet."}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Two Column Layout */}
                  <div className="grid grid-cols-2 gap-6">
                    {/* Key Topics Section */}
                    <div className="">
                      <div className="flex items-center gap-3">
                        <div className="p-2 rounded-lg bg-indigo-200">
                          <BookOpen className="w-5 h-5 text-indigo-600" />
                        </div>
                        <h3 className="text-lg font-semibold text-indigo-900">
                          Key Topics Discussed
                        </h3>
                      </div>
                      {isEditing ? (
                        <Textarea
                          value={sessionSummary.questions}
                          onChange={(e) => setSessionSummary({
                            ...sessionSummary,
                            questions: e.target.value
                          })}
                          rows={4}
                          placeholder="List the main topics covered during the session..."
                          className="w-full bg-white"
                        />
                      ) : (
                        <div className="bg-white px-4 rounded-lg border border-indigo-200">
                          <p className="text-gray-700 whitespace-pre-wrap">
                            {sessionSummary.questions}
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Main Focus Area Section */}
                    <div className="">
                      <div className="flex items-center gap-3">
                        <div className="p-2 rounded-lg bg-indigo-200">
                          <Target className="w-5 h-5 text-indigo-600" />
                        </div>
                        <h3 className="text-lg font-semibold text-indigo-900">
                          Main Focus Area
                        </h3>
                      </div>
                      {isEditing ? (
                        <Textarea
                          value={sessionSummary.primaryConcern}
                          onChange={(e) => setSessionSummary({
                            ...sessionSummary,
                            primaryConcern: e.target.value
                          })}
                          placeholder="Describe the primary focus or concern addressed..."
                          className="w-full bg-white"
                        />
                      ) : (
                        <div className="bg-white px-4 rounded-lg border border-indigo-200">
                          <p className="text-gray-700">
                            {sessionSummary.primaryConcern}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="transcript" activeValue={activeTab}>
                <div className="p-6">
                  <p className="text-center text-gray-500 py-12">
                    Transcript view will be implemented in the next phase.
                  </p>
                </div>
              </TabsContent>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default LeadChart;