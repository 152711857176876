import './Client.css';
import { Route, Routes } from "react-router-dom";
import ClientChart from './components/clientChart/clientChart';
import ClientHome from './components/clientsDetails/clientHome';
import LeadClieenList from './components/leadClients/leadClieenList/leadClieenList';
import LeadChart from './components/leadClients/leadChart/leadChart';

function ClientScreen(){
    return <div className="client-screen-wrapper">
        <Routes>
            <Route path="/" element={<ClientHome/>}/>
            <Route path="/leads" element={<LeadClieenList/>}/>
            <Route path="/client-chart" element={<ClientChart/>}/>
            <Route path="/lead-chart" element={<LeadChart/>}/>
        </Routes>
    </div>
}

export default ClientScreen;